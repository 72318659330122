var fetchJsonp = require('fetch-jsonp'); // We just need to require it to make the fetch function available

var RootUrl = 'https://graph.facebook.com/';

var orcAuthToken = null;

module.exports = {

    get: function(url){

        return fetchJsonp(RootUrl + url, {
            headers: { 'x-access-token' : orcAuthToken}
        })
        .then(function(response){
           return response.json();
        });
    }

};