var React = require('react');

module.exports = React.createClass({
    render :function(){
        return <header id="myCarousel" className="carousel slide">
            <ol className="carousel-indicators">
                <li data-target="#myCarousel" data-slide-to="0" className="active"></li>
                <li data-target="#myCarousel" data-slide-to="1"></li>
                <li data-target="#myCarousel" data-slide-to="2"></li>
                <li data-target="#myCarousel" data-slide-to="3"></li>
            </ol>

            <div className="carousel-inner">
                <div className="item active">
                    <div className="fill" style={{"backgroundImage":"url('img/notusclose.jpg')"}}></div>
                    <div className="carousel-caption">
                        <h2>F1 Proven Physics Engine</h2>
                    </div>
                </div>
                <div className="item">
                    <div className="fill" style={{"backgroundImage":"url('img/helmet.jpg')"}}></div>
                    <div className="carousel-caption">
                        <h2>Records / Achievements / Stats Built In</h2>
                    </div>
                </div>
                <div className="item">
                    <div className="fill" style={{"backgroundImage":"url('img/startline.jpg')"}}></div>
                    <div className="carousel-caption">
                        <h2>Race up to 20 players online</h2>
                    </div>
                </div>
                <div className="item">
                    <div className="fill" style={{"backgroundImage":"url('img/beltoy.jpg')"}}></div>
                    <div className="carousel-caption">
                        <h2>Get involved - Download the alpha now!</h2>
                    </div>
                </div>
            </div>

            <a className="left carousel-control" href="#myCarousel" data-slide="prev">
                <span className="icon-prev"></span>
            </a>
            <a className="right carousel-control" href="#myCarousel" data-slide="next">
                <span className="icon-next"></span>
            </a>
        </header>
    }
});