var React = require("react");
var Reflux = require('reflux');

var Actions = require('../actions');
var StatsStore = require('../stores/stats-store');
var DriversTable = require('../components/drivers_table');

module.exports = React.createClass({

    mixins: [Reflux.listenTo(StatsStore, 'onStatsEvent')],

    getInitialState: function () {
        return {rankLeaders: [], rankLeadersTable:[], showMoreLabel: true};
    },

    componentDidMount: function () {
        Actions.getPointsLeaderBoard();
    },

    onStatsEvent: function (event, data) {
        if (event == 'getPointsLeaderBoardResult') {
            this.setState({rankLeaders: data})
        }
    },

    render: function () {
        return <DriversTable
                    title="Top Point Scorers"
                    driversList={this.state.rankLeaders}
                    headers={ ['Position', 'Driver', 'Points']}
                    dataValue="points"
                    driverId={this.props.driverId}
        />
    }

});