var React = require('react');
var validator = require('validator');
var Validation = require('react-validation');

var Api = require('../utils/api');

var Footer = require('../components/footer');

// Extend Validation with custom rules
Validation.extendErrors({
    isRequired: {
        className: 'has-error',
        message: 'required',
        rule: function (value) {
            return Boolean(validator.trim(value));
        }
    },
    isEmail: {
        className: 'has-error',
        // validator already has strong email-pattern, so we don't have to extend it by custom
        message: 'Invalid e-mail'
    }
});

module.exports = React.createClass({

    getInitialState: function () {
        return {sent: false, error: false};
    },

    onChange: function (event) {
        var stateObject = function () {
            var returnObj = {};
            returnObj[this.target.id] = this.target.value;
            return returnObj;
        }.bind(event)();

        this.setState(stateObject);
    },

    resetClick: function (event) {
        event.preventDefault();
        Api.post('/user/forgot', this.state).then(function (result) {
            if (result.status == 'success') {
                this.setState({sent: true});
            } else {
                this.setState({error: true});
            }
        }.bind(this)).catch(function (e) {
            this.setState({error: true});
        }.bind(this))
    },

    render: function () {

        return <div className="container full-height">

            { this.showErrorBox() }

            <div className="row">
                <div className="col-lg-12">
                    <h1 className="page-header">Reset Password
                    </h1>
                    <ol className="breadcrumb">
                        <li><a href="index.html">Home</a>
                        </li>
                        <li className="active">Reset Password</li>
                    </ol>
                </div>
            </div>

            <div className="row">
                <div className="col-lg-12">
                    <img className="img-responsive" src="img/reflections.jpg" alt=""/>
                </div>
            </div>

            <div className="row">
                {this.showFormOrResult()}
            </div>

            <hr />

            <Footer/>
        </div>
    },

    showFormOrResult: function () {
        if (!this.state.sent) {

            return <div className="col-md-8">
                <br/><b>*NOTE*</b> You will receive an email after you reset. Please reset your password by
                clicking on the link in the email.
                <br/><br/>
                <Validation.Form name="sentMessage" id="contactForm">

                    <div className="control-group form-group">
                        <div className="controls">
                            <label>Email*</label>
                            <Validation.Input type="email" className="form-control" onChange={this.onChange}
                                              id="email" name="email" blocking='input' placeholder=""
                                              validations={[{rule: 'isRequired'}, {rule: 'isEmail'}]}/>
                        </div>
                    </div>


                    <Validation.Button onClick={this.resetClick} blocking='button' value='Reset'
                                       className="btn btn-primary"/>

                </Validation.Form>
            </div>

        } else {
            return <div><br/><br/>
                <div className="alert alert-success">
                    <strong>Success!</strong> Please check your email for instructions on how to reset your email
                </div>
            </div>
        }
    },

    showErrorBox: function () {
        if (this.state.error) {
            return <div className="alert alert-error">
                <a href="#" className="close" data-dismiss="alert">&times;</a>
                <strong>Error!</strong> A problem has occurred trying to reset your password, please check your email
                again</div>

        }
    }

});