var React = require('react');
var Link = require('react-router').Link;

var Tracks = require('../utils/tracks');
var Time = require('../utils/time');

var newsTypes = {
    "PB": { txt: "set a personal best lap-time at"},
    "WR": { txt: "set a world record lap-time at"}
};

module.exports = React.createClass({

    // 'PB: Bob broke his personal best at Beltoy Park - National (1:23.233)',
    // 'WR: John broke the world record at Beltoy Park - International (1:14.231)',
    // 'Ash got a bronze laptime at Beltoy Park - International Chicane (1:14:124)',
    // 'Achievement: Lyns has driven 1000 laps!',
    // 'Achievement: Jake has won 100 races!',
    // 'PB: Ben has broke his personal best at Beltoy Park - Club Short (0:25:123)'

    getInitialState: function(){
        return { items: [ { playerId: 2, name: 'Bob McConnell', type: 'PB', trackId: 12, amount: 100.234},
            { playerId: 1077, name: 'John Jones', type: 'WR', trackId: 12, amount: 70.234}],
            index : 0};
    },

    componentDidMount: function(){
       var timeout = setTimeout(this.getNextItem, 5000);
        this.setState({ timeout: timeout});
    },

    componentWillUnmount: function(){
        clearTimeout(this.state.timeout);
    },

    getNextItem: function(){
        var index = (this.state.index + 1) % this.state.items.length;
        var timeout = setTimeout(this.getNextItem, 5000);

        this.setState({ index: index, timeout: timeout});
    },

    render: function (){

        var item =  this.state.items[this.state.index];

        var itemDiv = <span className="ticker-item" key={item.id}>
                    {this.renderItemType(item)}
                </span>;

        return <div className="ticker-container">
                {itemDiv}
        </div>
    },

    renderItemType: function(item){
        var newsType = newsTypes[item.type];

        switch(item.type){
            case 'PB':
            case 'WR':
                return <span><b>{item.type}</b>: <Link to={'/profile/' + item.playerId}>{item.name}</Link> {newsType.txt} <b>{Tracks.getLocationAndName(item.trackId)}</b> ({Time.toMinSecs(item.amount)}) </span>;

        }
    }
});