var React = require('react');

module.exports = React.createClass({

    render: function () {
        return <div className="col-md-1 text-center" title={this.props.description}>
            <div className="thumbnail relative">
                <h2><i className={ "fa " + this.props.icon}></i></h2>
                <span className="triangle" style={{"borderTop" : "20px groove " + this.props.colour}} />
            </div>
        </div>
    }
});