var React = require('react');
var Reflux = require('reflux');
var Link = require('react-router').Link;

var Carousel = require('../components/carousel');
var Footer = require('../components/footer');
var TopDrivers = require('../components/top-drivers');
var TopPointsDrivers = require('../components/top-points');
var WorldRecords = require('../components/world_records');
var NewsTicker = require('../components/news-ticker');

var LoginStore = require('../stores/login-store');

module.exports = React.createClass({

    mixins: [
        Reflux.listenTo(LoginStore, 'onEvent'),
    ],

    getInitialState: function () {
        return {loggedInUser: null, loggedInUserId: false};
    },

    onEvent: function (event, data) {
        switch (event) {
            case 'myDetails':
                this.setState({loggedInUser: data, loggedInUserId: data.id});
                break;
            case 'logout':
                this.setState({loggedInUser: null, loggedInUserId: false});
                break;
        }
    },

    render: function () {
        return <span style={{"height" : "100%"}}>

        <Carousel />
        <div className="container">
            <br/>

            <div className="well">
                <div className="row">
                    <div className="col-md-12">
                        <NewsTicker />
                    </div>
                </div>
            </div>

            <div className="row">

                <div className="col-md-4">
                    <div className="panel panel-default">
                        <div className="panel-heading">
                            <h4><i className="fa fa-fw fa-cogs"></i> F1 Proven Physics Engine</h4>
                        </div>
                        <div className="panel-body">
                            <p>The physics engine used in ORC was developed by <b>Dr. Gregor Veble</b> and used within
                                an <b>F1
                                    team</b>. This means that the core of the simulator has been evaluated by a team of
                                formula
                                1 engineers and tested by a formula 1 driver to ensure <b>maximum realism</b>.</p>
                            <Link to="/features/physics" className="btn btn-default">Learn More</Link>
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="panel panel-default">
                        <div className="panel-heading">
                            <h4><i className="fa fa-fw fa-trophy"></i> Achievements / Stats</h4>
                        </div>
                        <div className="panel-body">
                            <p>We have a <b>licence system</b> that shows driver experience. There is an <b>ORC rank</b>
                                which shows how fast a driver is. There are <b>achievements</b> to gain along the way
                                while racing your friends and rivals online. All these <b>stats</b> can be found on your
                                <b> driver page</b></p>
                            <Link to="/features/achievements" className="btn btn-default">Learn More</Link>
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="panel panel-default">
                        <div className="panel-heading">
                            <h4><i className="fa fa-fw fa-signal"></i> Smooth Networking</h4>
                        </div>
                        <div className="panel-body">
                            <p>We have developed an <b>advanced smoothing technique</b> using the physics engine in
                                order to ensure <b>smooth racing</b> online even with reasonably high pings. This <b>reduces
                                    the effect of lag</b> and is the key to close, competitive racing - something that
                                we love!</p>
                            <Link to="/features/networking" className="btn btn-default">Learn More</Link>
                        </div>
                    </div>
                </div>
            </div>

            <TopDrivers driverId={this.state.loggedInUserId}/>
            <TopPointsDrivers driverId={this.state.loggedInUserId}/>
            <WorldRecords driverId={this.state.loggedInUserId}/>


            <hr />

            <div className="well">
                <div className="row">
                    <div className="col-md-8">
                        <p>We currently have an free alpha test version of ORC with a single car and 8 track layouts,
                            please give it a try and give us feedback on our forum</p>
                    </div>
                    <div className="col-md-4">
                        <Link className="btn btn-lg btn-danger btn-block" to="/downloads">Download Alpha!</Link>
                    </div>
                </div>
            </div>

            <hr />

            <Footer />

        </div>

            </span>
    }
});