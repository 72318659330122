var Reflux = require('reflux');
var _ = require('lodash');

var Actions = require('../actions');
var Api = require('../utils/facebook-api');


module.exports = Reflux.createStore({
    listenables: [Actions],
    getFacebookAlbums: function(){
        return Api.get('191330299072/albums?access_token=155264007389|a3159e71cac0669c9404faef5a7b1098&fields=cover_photo,description,name').then(function (response){
            this.albums = _.reject( response.data, function(album){
                return album.name == 'Cover Photos'
                    || album.name == 'Timeline Photos'
                    || album.name == 'Profile Pictures';
            });
            this.trigger('albumsSuccess', this.albums);
        }.bind(this));
    },

    getFacebookAlbum: function(albumId){

        return Api.get(albumId + '/photos?access_token=155264007389|a3159e71cac0669c9404faef5a7b1098&fields=images,source,name').then(function (response){
            this.photos = response.data;
            this.trigger('photosSuccess', this.photos);
        }.bind(this));
    },

    setCurrentAlbum: function(albumId){
        if (!this.albums) {
            this.getFacebookAlbums();
        }else {
            this.album  = _.findWhere(this.albums, { id: albumId });
            this.trigger('albumChanged', this.album);
        }
    }

});