var React = require("react");
var Config = require('../config');
var Link = require('react-router').Link;

module.exports = React.createClass({

    render: function () {
        return <div title={this.props.name} className="col-md-1 text-center">
            <Link to={"/profile/" + this.props.driver}>
                <div className="thumbnail relative">
                    <img src={Config.apiUrl + '/user/avatar/' + this.props.driver + "?size=s"}/>
                </div>
            </Link>
        </div>
    }
});