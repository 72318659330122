var React = require('react');

module.exports = React.createClass({

    render: function () {
        return <span> <div className="panel panel-default">
            <div className="panel-heading">
                <h2 className="panel-title">Achievements / Ranks and Licences</h2>
            </div>
            <div className="panel-body">
                <h4>Achievements</h4>
                We have achievements in ORC to collect on your racing career, you can see your achievements on your driver's profile page
                <h4>Ranks</h4>
                Every time you race your lap-time and quickest sectors will be recorded in our ranking system, you can compare your lap-times / sector times / wins / points with your friends and try to climb the rank.
                <h4>Licences</h4>
                Each driver has a coloured licence (from White to Black) to indicate the driver's experience.
            </div>
        </div>
        </span>
    }
});