var React = require('react');
var Link = require('react-router').Link;

var AvatarEditor = require('react-avatar-editor');
var Modal = require('boron/WaveModal');
var Slider = require('rc-slider');

var modalStyle = {
    width: 'auto'
};

function base64toBlob(base64Data, contentType) {
    contentType = contentType || '';
    var sliceSize = 1024;
    var byteCharacters = atob(base64Data);
    var bytesLength = byteCharacters.length;
    var slicesCount = Math.ceil(bytesLength / sliceSize);
    var byteArrays = new Array(slicesCount);

    for (var sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
        var begin = sliceIndex * sliceSize;
        var end = Math.min(begin + sliceSize, bytesLength);

        var bytes = new Array(end - begin);
        for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
            bytes[i] = byteCharacters[offset].charCodeAt(0);
        }
        byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, {type: contentType});
}

module.exports = React.createClass({

    getInitialState: function (){
            return {
                scale: 1.0
            }
    },

    hideModal: function () {
        this.refs.modal.hide();
    },

    show: function(){
        this.refs.modal.show();
    },

    setScale: function(scale){
        this.setState( { scale: scale });
    },

    scaleChanged: function(e){
        this.setState({ scale: e});
    },

    getImage: function(){
        console.log('getting jpg image');
        return this.refs.editor.getImage('image/jpeg', 0.8);
    },

    getResizedImage : function(width, height) {
        var resize_canvas = document.createElement('canvas');
        resize_canvas.width = width;
        resize_canvas.height = height;
        var dataURL = this.refs.editor.getImage('image/jpeg', 1);

        var jpg = new Image();
        jpg.src = dataURL;

        resize_canvas.getContext('2d').drawImage(jpg, 0, 0, width, height);
        return resize_canvas.toDataURL("image/jpeg", 0.8);
    },

    save: function(e){
        this.hideModal();
        if (this.props.save) {
            this.props.save(e);
        }
    },

    render: function () {

        return <Modal ref='modal' modalStyle={modalStyle}>
            <div className="" tabIndex="-1">
                <div className="large">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" onClick={this.hideModal} className="close"
                                    aria-label="Close"><span aria-hidden="true">&times;</span></button>
                            <h4 className="modal-title">{ this.props.title }</h4>
                        </div>
                        <div className="modal-body text-center">
                            <AvatarEditor
                                ref="editor"
                                image={this.props.image}
                                width={this.props.width}
                                height={this.props.height}
                                scale={this.state.scale}
                            />
                            <Slider min={0.1} max={10} step={0.1} value={this.state.scale} tipTransitionName="rc-slider-tooltip-zoom-down" onChange={this.scaleChanged} />
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-default" onClick={this.hideModal}
                                    data-dismiss="modal">Close
                            </button>
                            <button type="button" className="btn btn-primary" onClick={this.save}>Save
                                changes
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    }
});