var React = require('react');
var Reflux = require('reflux');
var Link = require('react-router').Link;

var Config = require('../config');
var Actions = require('../actions');
var LoginStore = require('../stores/login-store');
var FriendsStore = require('../stores/friends-store');
var StatsStore = require('../stores/stats-store');
var Footer = require('../components/footer');
var Badge = require('../components/achievement-badge');
var PlayerHeader = require('../components/profile-player-header');
var BestLaps = require('../components/profile-bestlaps');
var DriverThumb = require('../components/driver-thumbnail');

var getOrdinal = require('../utils/ordinal');
var TrackUtils = require('../utils/tracks');

var Points = require('../utils/points');


module.exports = React.createClass({

    mixins: [
        Reflux.listenTo(LoginStore, 'onEvent'),
        Reflux.listenTo(FriendsStore, 'onEvent'),
        Reflux.listenTo(StatsStore, 'onEvent')
    ],

    getInitialState: function () {

        var achievements = [
            {
                id: 1,
                icon: 'fa-thumbs-o-up',
                colour: 'red',
                description: 'Early Adopter - Played ORC before it was cool :)'
            },
            {id: 2, icon: 'fa-clock-o', colour: 'green', description: 'World Record Holder'},
            {id: 3, icon: 'fa-trophy', colour: 'blue', description: '100 time race winner'},
            {
                id: 4,
                icon: 'fa-thumbs-o-up',
                colour: 'gold',
                description: 'Early Adopter - Played ORC before it was cool :)'
            },
            {id: 5, icon: 'fa-clock-o', colour: 'black', description: 'World Record Holder'},
            {id: 6, icon: 'fa-trophy', colour: 'silver', description: '100 time race winner'},
            {
                id: 7,
                icon: 'fa-thumbs-o-up',
                colour: 'brown',
                description: 'Early Adopter - Played ORC before it was cool :)'
            },
            {id: 8, icon: 'fa-clock-o', colour: 'purple', description: 'World Record Holder'},
            {id: 9, icon: 'fa-trophy', colour: 'orange', description: '100 time race winner'},
            {
                id: 10,
                icon: 'fa-thumbs-o-up',
                colour: 'pink',
                description: 'Early Adopter - Played ORC before it was cool :)'
            },
            {id: 11, icon: 'fa-clock-o', colour: 'white', description: 'World Record Holder'},
            {id: 12, icon: 'fa-trophy', colour: 'violet', description: '100 time race winner'},
            {
                id: 13,
                icon: 'fa-thumbs-o-up',
                colour: 'black',
                description: 'Early Adopter - Played ORC before it was cool :)'
            },
            {id: 14, icon: 'fa-clock-o', colour: 'gold', description: 'World Record Holder'},
            {id: 15, icon: 'fa-trophy', colour: 'red', description: '100 time race winner'},
            {
                id: 16,
                icon: 'fa-thumbs-o-up',
                colour: 'green',
                description: 'Early Adopter - Played ORC before it was cool :)'
            },
            {id: 17, icon: 'fa-clock-o', colour: 'red', description: 'World Record Holder'},
            {id: 18, icon: 'fa-trophy', colour: 'blue', description: '100 time race winner'}
        ];

        return {
            player: null,
            achievements: achievements,
            loggedInUser: null,
            loggedInUserId: false,
            wrs: null,
            following: [],
            followers: [],
            bestLaps: [],
            scale: 1.5
        };
    },

    componentDidMount: function () {
        this.callActions(this.props.params.id);
    },

    componentWillReceiveProps: function (nextProps) {
        this.callActions(nextProps.params.id);
    },

    callActions: function (id) {
        Actions.getPbsForPlayer(id);
        Actions.getFollowing(id);
        Actions.getFollowers(id);
        Actions.getWorldRecords();
        Actions.getUserDetails(id);
        Actions.getMyDetails();
        window.scrollTo(0, 0);
    },

    storePlayerState: function (user) {
        var licence = Points.getRankAndColourFromPoints(user.points);
        var nextLicence = Points.getNextRankInfo(licence.level, licence.licenceIndex);
        nextLicence.pointsDiff = nextLicence.points - user.points;
        var licenceStyle = {
            color: licence.colour
        };
        var nextLicenceStyle = {
            color: nextLicence.colour
        };

        this.setState({
            player: user,
            licence: licence,
            nextLicence: nextLicence,
            licenceStyle: licenceStyle,
            nextLicenceStyle: nextLicenceStyle
        });
    },


    onEvent: function (event, data) {
        switch (event) {
            case 'userDetails':
                this.storePlayerState(data);
                break;
            case 'myDetails':
                this.setState({loggedInUser: data, loggedInUserId: data.id});
                break;
            case 'playerPbs':
                this.setState({bestLaps: TrackUtils.groupTrackInfoListByLocation(data)});
                break;
            case 'getFollowersResult':
                this.setState({followers: data});
                break;
            case 'getFollowingResult':
                this.setState({following: data});
                break;
            case 'getWorldRecordsResult':
                this.setState({wrs: data});
                break;
        }
    },


    followChange: function (event) {
        if (event == 'followed') {
            var followers = this.state.followers.slice(0);
            followers.unshift(this.state.loggedInUserId);
            this.setState({followers: followers});
        } else {
            var followers = this.state.followers.slice(0);
            var index = followers.indexOf(this.state.loggedInUserId);
            if (index > -1) {
                followers.splice(index, 1);
            }
            this.setState({followers: followers});
        }
    },

    render: function () {
        if (this.state.player) {
            return <div className="container full-height ">
                <div className="fb-profile profile-border">

                    <PlayerHeader playerId={this.props.params.id}/>

                    <div className="row">
                        <div className="col-lg-12">
                            <ol className="breadcrumb profile-bread">
                                <li><a href="index.html">Home</a>
                                </li>
                                <li className="active">Profile</li>
                            </ol>
                        </div>
                    </div>

                    <div className="profile-detail">
                        <h2>Stats</h2>
                    </div>
                    <div className="row stats-box">
                        <div className="col-md-4 text-center" title="All laptimes VS benchmark times">
                            <div className="thumbnail">
                                <h2><i className="fa fa-clock-o fa-4x"></i></h2>
                                <div className="caption">
                                    <h3>ORC Rank: {this.state.player.orcRank.toFixed(2)} <br />
                                        <small>{ getOrdinal(this.state.player.worldRank) + ' in the world'}</small>
                                    </h3>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 text-center">
                            <div className="thumbnail">
                                <Link to={ '/points/' + this.state.player.id }>
                                    <h2><i className="fa fa-trophy fa-4x"></i></h2>
                                    <div className="caption">
                                        <h3>Points: { this.state.player.points }<br />
                                            <small>{ getOrdinal(this.state.player.worldPointsRank) + ' in the world'}</small>
                                        </h3>
                                    </div>
                                </Link>
                            </div>
                        </div>
                        <div className="col-md-4 text-center">
                            <div className="thumbnail" style={this.state.licenceStyle}>
                                <h2><i className="fa fa-user fa-4x"></i></h2>
                                <div className="caption">
                                    <h3>Licence: <span
                                        style={this.state.licenceStyle}>{this.state.licence.licenceName}</span>
                                        [{this.state.licence.level}]<br />
                                        <small>Next - <span
                                            style={this.state.nextLicenceStyle}>{this.state.nextLicence.licenceName}</span>
                                            [{this.state.nextLicence.level}] (+{this.state.nextLicence.pointsDiff}
                                            points)
                                        </small>
                                    </h3>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="profile-detail">
                        <h2>Personal Bests</h2>
                    </div>

                    <div className="row stats-box">
                        <BestLaps laps={this.state.bestLaps} worldRecords={this.state.wrs}/>
                    </div>

                    { this.renderFollowing() }
                    { this.renderFollowers() }

                </div>
                <Footer />
            </div>
        } else {
            return <div className="container full-height">

            </div>
        }
    },


    renderAchievementBadges: function () {
        return this.state.achievements.map(function (ach) {
            return <Badge key={ach.id} description={ach.description} colour={ach.colour} icon={ach.icon}/>
        });
    },

    renderFollowing: function () {

        if (this.state.following.length == 0) {
            return null;
        }

        var followingList = this.state.following.map(function (leader) {
            return <DriverThumb key={leader.id} driver={leader.id} name={leader.firstname + ' ' + leader.lastname}/>
        });

        return <span>
                    <div className="profile-detail">
                        <h2>Following</h2>
                    </div>
                    <div className="row stats-box">
                        { followingList }
                    </div>
        </span>;
    },

    renderFollowers: function () {
        if (this.state.followers.length == 0) {
            return null;
        }

        var leaderList = this.state.followers.map(function (follower) {
            return <DriverThumb key={follower.id} driver={follower.id}
                                name={follower.firstname + ' ' + follower.lastname}/>
        });

        return <span>
                    <div className="profile-detail">
                        <h2>Followers</h2>
                    </div>
                    <div className="row stats-box">
                        { leaderList }
                    </div>
        </span>;
    }
});
