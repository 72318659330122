var React = require("react");
var Reflux = require('reflux');

var Actions = require('../actions');
var StatsStore = require('../stores/stats-store');
var BestLaps = require('../components/profile-bestlaps');

var TrackUtils = require('../utils/tracks');

module.exports = React.createClass({

    mixins: [Reflux.listenTo(StatsStore, 'onStatsEvent')],

    getInitialState: function () {
        return {worldRecords: []};
    },

    componentDidMount: function () {
        Actions.getWorldRecords();
    },

    onStatsEvent: function (event, data) {
        if (event == 'getWorldRecordsResult') {
            this.setState({worldRecords: TrackUtils.groupTrackInfoListByLocation(data)})
        }
    },

    render: function () {
        return <span>
            <div className="row">
                <div className="col-lg-12">
                    <h2 className="page-header">World Records</h2>
                </div>
            </div>
            <BestLaps laps={this.state.worldRecords} showDriver={true} driverId={this.props.driverId}/>
        </span>
    }

});