var React = require('react');
var Link = require('react-router').Link;
var Reflux = require('reflux');

var Api = require('../utils/api');
var Actions = require('../actions');
var LoginForm = require('./login-form');
var LoginStore = require('../stores/login-store');

var Config = require('../config');

var Autosuggest = require('react-autosuggest');
var createHashHistory = require('history/lib/createHashHistory');
const history = createHashHistory();

function getSuggestionValue(suggestion) {
    return suggestion.name;
}

function shouldRenderSuggestions(value) {
    return value.trim().length > 2;
}

module.exports = React.createClass({
    mixins: [Reflux.listenTo(LoginStore, 'onLoginEvent')],

    getInitialState: function () {
        return {user: null, loggingIn: false, value: '', suggestions: [], loginFailed: false};
    },
    componentDidMount: function () {
        Actions.getMyDetails();
    },
    onLoginEvent: function (event, data) {
        switch (event) {
            case 'loginSuccess':
                Actions.getMyDetails();
                break;
            case 'myDetails':
                this.setState({user: data, loginFailed: false});
                break;
            case 'loginFailed':
                this.setState({loginFailed: true});
                break;
            case 'logout':
                this.setState({user: null, loginFailed: false});
                break;
        }
    },
    onLogoutClicked: function () {
        Actions.logout();
    },
    profileClicked: function () {
        history.pushState(null, '/profile/' + this.state.user.id);
    },
    getLoginInfo: function () {
        if (this.state.user) {
            return <li className="dropdown">
                <a href="#" className="dropdown-toggle" data-toggle="dropdown">{ this.state.user.nickName } <b
                    className="caret"></b></a>
                <ul className="dropdown-menu login-menu">
                    <li className="driver-menu-item" onClick={this.profileClicked}>Profile</li>
                    <li className="driver-menu-item" onClick={this.onLogoutClicked}>Logout</li>
                </ul>

            </li>
        } else {
            return <li className="dropdown">
                <a href="#" className="dropdown-toggle" data-toggle="dropdown">Login <b
                    className="caret"></b></a>
                <ul className="dropdown-menu login-menu">
                    <LoginForm hasError={this.state.loginFailed}/>
                </ul>
            </li>
        }
    },

    loadSuggestions: function(value) {
        this.setState({
            isLoading: true
        });

        if (value.length > 2) {
            Api.get('/search?q=' + value).then(function (players) {
                this.setState({suggestions: players, isLoading: false});
            }.bind(this)).catch(function (e) {
                this.setState({
                    isLoading: false
                });
            }.bind(this));
        }
    },

    onSuggestionSelected: function (event, result) {
        event.preventDefault();
        history.pushState(null, '/profile/' + result.suggestion.id);
        this.setState({isLoading: false, value: ''})
    },

    onSuggestionsUpdateRequested: function (result) {
        this.loadSuggestions(result.value);
    },

    onSuggestionsFetchRequested: function(result) {
        this.loadSuggestions(result.value);
    },

    onSuggestionsClearRequested: function() {
        this.setState({
            suggestions: []
        });
    },

    renderSuggestion: function (suggestion) {
        return (
            <span><img style={ {marginLeft: '-10', marginRight:'5'} }
                       src={Config.apiUrl + '/user/avatar/' + suggestion.id + "?size=xs"}/> {suggestion.name}</span>
        );
    },

    onChange: function (event, result) {
        event.preventDefault();
        this.setState({
            value: result.newValue
        });
    },

    render: function () {

        var inputProps = {
            placeholder: 'Search',
            value: this.state.value,
            onChange: this.onChange
        };

        return <div>
            <nav className="navbar navbar-inverse navbar-fixed-top" role="navigation">
                <div className="container">
                    <div className="navbar-header">
                        <button type="button" className="navbar-toggle" data-toggle="collapse"
                                data-target="#bs-example-navbar-collapse-1">
                            <span className="sr-only">Toggle navigation</span>
                            <span className="icon-bar"></span>
                            <span className="icon-bar"></span>
                            <span className="icon-bar"></span>
                        </button>
                        <Link className="navbar-brand" to="/"><img className="header-image" src="img/OrcLogoSite.png"/></Link>
                    </div>
                    <div className="collapse navbar-collapse" id="bs-example-navbar-collapse-1">

                        <ul className="nav navbar-nav navbar-right">
                            <li>
                                <Link to="/downloads" className="btn btn-danger">Download Alpha</Link>
                            </li>
                            <li>
                                <Link to="/features">Features</Link>
                            </li>
                            <li>
                                <Link to="/media">Media</Link>
                            </li>
                            <li>
                                <Link to="/news">News</Link>
                            </li>
                            <li>
                                <Link to="/about">About</Link>
                            </li>
                            { this.getLoginInfo() }
                        </ul>
                        <div className="col-sm-3 col-md-3">
                            <div className="navbar-form" role="search">
                                <Autosuggest suggestions={this.state.suggestions}
                                             getSuggestionValue={getSuggestionValue}
                                             renderSuggestion={this.renderSuggestion}
                                             onSuggestionsUpdateRequested={this.onSuggestionsUpdateRequested}
                                             onSuggestionSelected={this.onSuggestionSelected}
                                             shouldRenderSuggestions={shouldRenderSuggestions}
                                             onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                                             onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                                             inputProps={inputProps}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    }
});