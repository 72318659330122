var React = require("react");
var Link = require('react-router').Link;

module.exports = React.createClass({

    render: function () {
        return <div className="col-md-4 text-center">
            <div className="thumbnail">
                <Link to={"/profile/" + this.props.orcid }>
                    <img className="img-responsive" src={ 'img/' + this.props.pic + '_profile.jpg' }/>
                </Link>
                <div className="caption">
                    <h3>{this.props.name}<br />
                        <small>{this.props.job }</small>
                    </h3>
                    <ul className="list-inline">
                        <li>
                            <a href={ 'http://twitter.com/' + this.props.twitter} target="_blank"><i
                                className="fa fa-2x fa-twitter-square"></i></a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    }
});
