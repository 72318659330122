var React = require('react');
var Footer = require('../components/footer');
var Physics = require('../components/physics-feature');
var PhysicsExtras = require('../components/physics-feature-extras');
var Achievements = require('../components/achievements-feature');
var AchievementsExtras = require('../components/achievements-feature-extras');
var Networking = require('../components/networking-feature');
var NetworkingExtras = require('../components/networking-feature-extras');

var Link = require('react-router').Link;

function toTitleCase(str)
{
    return str.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
}

module.exports = React.createClass({

    render: function () {
        return <div className="container full-height">

            <div className="row">
                <div className="col-lg-12">
                    <h2 className="page-header">Features
                        <small> Alpha</small>
                    </h2>
                    <ol className="breadcrumb">
                        <li><Link to="/">Home</Link>
                        </li>
                        <li><Link to="/features">Features</Link>
                        </li>
                        <li className="active">{ toTitleCase(this.props.routeParams.feature)}</li>
                    </ol>
                </div>
            </div>

            <div className="row">
                <div className="col-lg-12">
                    <img className="img-responsive" src="img/header3.jpg" alt="" />
                </div>
            </div>

            <div className="row">
                <div className="col-lg-12">
                    <br />
                    { this.renderSelectedFeature() }
                    { this.renderSelectedFeatureExtras() }
                </div>
            </div>

            <hr />

            <Footer/>
        </div>
    },

    renderSelectedFeature: function(){
        switch (this.props.routeParams.feature){
            case 'physics':
                return <Physics/>
            case 'achievements':
                return <Achievements/>;
            case 'networking':
                return <Networking/>;
        }
    },

    renderSelectedFeatureExtras: function(){
        switch (this.props.routeParams.feature){
            case 'physics':
                return <PhysicsExtras/>
            case 'achievements':
                return <AchievementsExtras/>
            case 'networking':
                return <NetworkingExtras/>;
        }
    }




});