var React = require('react');
var Link = require('react-router').Link;

var Config = require('../config');

var Footer = require('../components/footer');
var TeamPic = require('../components/team-pic');




module.exports = React.createClass({

    render: function(){
        return <div className="container">

            <div className="row">
                <div className="col-lg-12">
                    <h1 className="page-header">About
                        <small> Online Racing Championship</small>
                    </h1>
                    <ol className="breadcrumb">
                        <li><a href="index.html">Home</a>
                        </li>
                        <li className="active">About</li>
                    </ol>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <img className="img-responsive" src="img/about.jpg" alt="" />
                </div>
                <div className="col-md-6">
                    <h2>About Online Racing Championship (ORC)</h2>
                    <p>ORC is a realistic racing simulator for the PC that is based on real world cars using a state of the art bespoke physics engine</p>
                    <p>It is developed by a small team of motorsports and sim racing enthusiasts.  We love sim racing and want ORC to become part of the sim-racing community</p>
                    <p>We are currently in <Link to="/downloads">alpha stage</Link>, please help us improve ORC into a world class racing experience.</p>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-12">
                    <h2 className="page-header">Our Team</h2>
                </div>
                <TeamPic name="Ash McConnell" job="ORC Developer" twitter="AshMcConnell" pic="ash" orcid="1" />
                <TeamPic name="Dr. Gregor Veble Mikić" job="Physics Developer" twitter="GregorVeble" pic="gregor" orcid="19" />
                <TeamPic name="Nick Ovey" job="Artist" twitter="nickovey" pic="nick" orcid="111" />
                <TeamPic name="Ali Goz" job="Sound Engineer" twitter="ali__goz" pic="ali" orcid="879" />
            </div>

            <hr />

            <Footer />
        </div>
   }
});
