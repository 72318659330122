var React = require('react');
var Link = require('react-router').Link;

module.exports = React.createClass({
    render: function () {

        return <footer>
            <div className="row">
                <div className="col-sm-12">
                    <ul className="nav navbar-nav navbar-right social">
                        <li>
                            <a href="mailto:ashley@siroccoracing.com"><i className="fa fa-2x fa-envelope"/></a>
                        </li>
                        <li><a href="https://facebook.com/OnlineRacingChampionship" target="_blank"><i
                            className="fa fa-2x fa-facebook-square"/></a></li>
                        <li><a href="http://twitter.com/orc" target="_blank"><i
                            className="fa fa-2x fa-twitter-square"/></a></li>
                        <li><a href="http://youtube.com/AshMcConnell" target="_blank"><i
                            className="fa fa-2x fa-youtube-square"/></a></li>
                    </ul>
                </div>
            </div>
        </footer>
    }
});