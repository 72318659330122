var Reflux = require('reflux');

var Actions = require('../actions');
var Api = require('../utils/api');

module.exports = Reflux.createStore({

    listenables: [Actions],

    checkFollowing: function (leader) {
        return Api.get('/friends/following/' + leader).then(function (result) {
            if (result && result.status == 'success') {
                this.trigger('canFollowResult', {leader: leader, following: result.following})
            } else {
                this.trigger('canFollowFailed', result);
            }
        }.bind(this));
    },

    follow: function (leader) {
        return Api.post('/friends/' + leader).then(function (result) {
            if (result && result.status == 'success') {
                this.trigger('followResult')
            } else {
                this.trigger('followFailed', result);
            }
        }.bind(this));
    },

    unfollow: function (leader) {
        return Api.delete('/friends/' + leader).then(function (result) {
            if (result && result.status == 'success') {
                this.trigger('unfollowResult')
            } else {
                this.trigger('unfollowFailed', result);
            }
        }.bind(this));
    },

    getFollowing: function (driverId) {
        return Api.get('/friends/leaders/' + driverId).then(function (result) {
            if (result && result.status == 'success') {
                this.trigger('getFollowingResult', result.leaders);
            } else {
                this.trigger('getFollowingFailed', result);
            }
        }.bind(this));
    },

    getFollowers: function (driverId) {
        return Api.get('/friends/followers/' + driverId).then(function (result) {
            if (result && result.status == 'success') {
                this.trigger('getFollowersResult', result.followers);
            } else {
                this.trigger('getFollowersFailed', result);
            }
        }.bind(this));
    }

});