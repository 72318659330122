var React = require('react');
var validator = require('validator');
var Validation = require('react-validation');
var router = require('../routes');

var Api = require('../utils/api');

var Footer = require('../components/footer');

// Extend Validation with custom rules
Validation.extendErrors({
    isRequired: {
        className: 'has-error',
        message: 'required',
        rule: function (value) {
            return Boolean(validator.trim(value));
        }
    },
    isEmail: {
        className: 'has-error',
        // validator already has strong email-pattern, so we don't have to extend it by custom
        message: 'Invalid e-mail'
    }
});

function stringStartsWith(string, prefix) {
    return string.slice(0, prefix.length) == prefix;
}

module.exports = React.createClass({

    getInitialState: function () {
        return {sent: false, error: false, invalid: false};
    },

    componentDidMount: function(){
        if (!this.props.location.query.email || !this.props.location.query.code ){
            this.setState({invalid:true});
        }else{
            this.setState({email:this.props.location.query.email,  code : this.props.location.query.code})
        }
    },

    onChange: function (event) {
        var stateObject = function () {
            var returnObj = {};
            returnObj[this.target.id] = this.target.value;
            return returnObj;
        }.bind(event)();

        if (stringStartsWith(event.target.id, 'password')) {
            this.checkPasswordsMatch();
        }


        this.setState(stateObject);
    },

    checkPasswordsMatch: function () {
        var password = this.refs.password;
        var passwordConfirm = this.refs.passwordConfirm;
        var hasValue = password.state.value && passwordConfirm.state.value;

        if (hasValue) {
            if (password.state.value === passwordConfirm.state.value) {
                password.hideError();
                passwordConfirm.hideError();
                return true;
            } else {
                password.showError('Passwords should match');
                passwordConfirm.showError('Passwords should match');
                return false;
            }
        }
    },

    resetClick: function (event) {
        event.preventDefault();
        if (this.checkPasswordsMatch()) {
            Api.post('/user/resetPassword', this.state).then(function (result) {
                if (result.status == 'success') {
                    this.setState({sent: true});
                } else {
                    this.setState({error: true});
                }
            }.bind(this)).catch(function (e) {
                console.log('error:', e);
                this.setState({error: true});
            }.bind(this))
        }
    },

    render: function () {

        return <div className="container full-height">

            { this.showErrorBox() }

            <div className="row">
                <div className="col-lg-12">
                    <h1 className="page-header">Reset Password
                    </h1>
                    <ol className="breadcrumb">
                        <li><a href="index.html">Home</a>
                        </li>
                        <li className="active">Reset Password</li>
                    </ol>
                </div>
            </div>

            <div className="row">
                <div className="col-lg-12">
                    <img className="img-responsive" src="img/reflections.jpg" alt=""/>
                </div>
            </div>

            <div className="row">
                {this.showFormOrResult()}
            </div>

            <hr />

            <Footer/>
        </div>
    },

    showFormOrResult: function () {
        if (!this.state.sent) {

            if (!this.state.invalid) {
                return <div className="col-md-8">
                    <br/><br/>

                    <Validation.Form name="sentMessage" id="contactForm">

                        <div className="control-group form-group">
                            <div className="controls">
                                <label>Password (min 6 characters)*</label>
                                <Validation.Input type="password" className="form-control" onChange={this.onChange}
                                                  id="password" name="password" ref='password' blocking='input' placeholder=""
                                                  validations={[{rule: 'isRequired'}]}/>
                            </div>
                        </div>

                        <div className="control-group form-group">
                            <div className="controls">
                                <label>Password Confirmation*</label>
                                <Validation.Input type="password" className="form-control" onChange={this.onChange}
                                                  id="passwordConfirm" name="passwordConfirm" ref='passwordConfirm' blocking='input'
                                                  placeholder=""
                                                  validations={[{rule: 'isRequired'}]}/>
                            </div>
                        </div>


                        <Validation.Button onClick={this.resetClick} blocking='button' value='Reset'
                                           className="btn btn-primary"/>

                    </Validation.Form>
                </div>
            }

        } else {
            return <div><br/><br/>
                <div className="alert alert-success">
                    <strong>Success!</strong> Your password has been reset
                </div>
            </div>
        }
    },

    showErrorBox: function () {
        if (this.state.error) {
            return <div className="alert alert-danger">
                <a href="#" className="close" data-dismiss="alert">&times;</a>
                <strong>Error!</strong> A problem has occurred trying to reset your password, please check your email
                again</div>

        }else if (this.state.invalid){
            return <div className="alert alert-danger">
                <a href="#" className="close" data-dismiss="alert">&times;</a>
                <strong>Error!</strong> The url was invalid</div>
        }
    }

});