var _ = require('lodash');


var tracks = {
    6: { location:	"Beltoy Park" , layout : "GP Chicane", length: 5.1, benchmark:83.34 },
    7: { location:	"Beltoy Park", layout : "Club Long", length: 6, benchmark:86.14 },
    8: { location:	"Beltoy Park", layout : "Club Short", length: 3.5, benchmark:40.546 },
    9: { location:	"Beltoy Park", layout : "Grand Prix", length: 6.5, benchmark:79.23 },
    11: { location:	"Beltoy Park", layout : "International Chicane", length: 5.2, benchmark:60.33 },
    10: { location:	"Beltoy Park", layout : "International", length: 6.2, benchmark:52.38 },
    12: { location:	"Beltoy Park", layout : "National", length: 3.5, benchmark:49.3 },
    13: { location:	"Beltoy Park", layout : "National Chicane", length: 3.7, benchmark:58.766 }
};

module.exports = {

    getTrackInfo: function(trackId){
        return tracks[trackId];
    },

    groupTrackInfoListByLocation: function(trackList){
        var locationHash =  trackList.reduce(function(locationMap, track){
            var location = this.getLocationFromTrackId(track.trackId);

            if (!locationMap.hasOwnProperty(location)){
                locationMap[location] = { name : location, layouts: []};
            }

            track.driverName = track.name;
            track.name = this.getTrackInfo(track.trackId).layout;

            locationMap[location].layouts.push(track);
            return locationMap;
        }.bind(this), {});

        return _.values(locationHash);
    },


    getLocationFromTrackId: function(trackId){
        return this.getTrackInfo(trackId).location;
    },

    getLocationAndName: function(trackId){
        var trackInfo = this.getTrackInfo(trackId);
        return trackInfo.location + ' - ' + trackInfo.layout;
    }

};