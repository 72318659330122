var React = require('react');
var Reflux = require('reflux');

var Config = require('../config');
var Actions = require('../actions');
var LoginStore = require('../stores/login-store');
var FriendsStore = require('../stores/friends-store');
var StatsStore = require('../stores/stats-store');
var Badge = require('../components/achievement-badge');
var ImageEditor = require('../components/image-editor');
var Avatar = require('../components/profile-pic');
var EditButtons = require('../components/edit-profile-buttons');
var FollowButton = require('../components/follow_button');
var DriverThumb = require('../components/driver-thumbnail');
var Banner = Avatar;

var Api = require('../utils/api');
var Points = require('../utils/points');

function base64toBlob(base64Data, contentType) {
    contentType = contentType || '';
    var sliceSize = 1024;
    var byteCharacters = atob(base64Data);
    var bytesLength = byteCharacters.length;
    var slicesCount = Math.ceil(bytesLength / sliceSize);
    var byteArrays = new Array(slicesCount);

    for (var sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
        var begin = sliceIndex * sliceSize;
        var end = Math.min(begin + sliceSize, bytesLength);

        var bytes = new Array(end - begin);
        for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
            bytes[i] = byteCharacters[offset].charCodeAt(0);
        }
        byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, {type: contentType});
}


module.exports = React.createClass({

    mixins: [
        Reflux.listenTo(LoginStore, 'onEvent'),
        Reflux.listenTo(FriendsStore, 'onEvent'),
        Reflux.listenTo(StatsStore, 'onEvent')
    ],

    getInitialState: function () {

        return {
            player: null,
            loggedInUser: null,
            loggedInUserId: false,
            editButtonEnabled: false,
            editMode: false,
            scale: 1.5
        };
    },

    componentDidMount: function () {
        this.callActions(this.props.playerId);
    },

    componentWillReceiveProps: function (nextProps) {
        this.enableLoggedInUserStuff();
    },

    callActions: function (id) {
        Actions.getUserDetails(id);
        Actions.getMyDetails();
        window.scrollTo(0, 0);
    },


    onEvent: function (event, data) {
        switch (event) {
            case 'userDetails':
                this.storePlayerState(data);
                break;
            case 'myDetails':
                this.setState({loggedInUser: data, loggedInUserId: data.id});
                break;
            case 'logout':
                this.setState({loggedInUser: null, loggedInUserId: false});
                break;
        }

        this.enableLoggedInUserStuff();
    },

    storePlayerState: function (user) {


        var selectedAvatarFile = Config.apiUrl + '/user/avatar/' + user.id;
        var selectedBannerFile = Config.apiUrl + '/user/banner/' + user.id;
        var avatarImage = selectedAvatarFile;
        var bannerImage = selectedBannerFile;

        this.setState({
            player: user,
            selectedAvatarFile: selectedAvatarFile,
            selectedBannerFile: selectedBannerFile,
            avatarImage: avatarImage,
            bannerImage: bannerImage
        });
    },

    enableLoggedInUserStuff: function () {
        if (this.state.loggedInUser && this.props.playerId) {
            this.setState({'editButtonEnabled': this.state.loggedInUserId == this.props.playerId});
        } else {
            this.setState({'editButtonEnabled': false});
        }
    },

    clickEdit: function () {
        this.setState({editMode: true});
    },

    clickSave: function () {
        Actions.updateUser(this.state.player);
        this.setState({editMode: false});
    },

    clickCancel: function () {
        Actions.getUserDetails(this.props.playerId);
        this.setState({editMode: false});
    },




    followChange: function (event) {
        if (event == 'followed') {
            var followers = this.state.followers.slice(0);
            followers.unshift(this.state.loggedInUserId);
            this.setState({followers: followers});
        } else {
            var followers = this.state.followers.slice(0);
            var index = followers.indexOf(this.state.loggedInUserId);
            if (index > -1) {
                followers.splice(index, 1);
            }
            this.setState({followers: followers});
        }
    },

    showAvatarFilePicker: function () {
        this.refs.avatarFile.value = null;
        this.refs.avatarFile.click();
    },

    showBannerFilePicker: function () {
        this.refs.bannerFile.value = null;
        this.refs.bannerFile.click();
    },

    avatarFileChanged: function (e) {
        if (e.target.value.toLowerCase().indexOf('.png') || e.target.value.toLowerCase().indexOf('.jpg')) {
            var reader = new FileReader();

            reader.onload = function (frEvent) {
                this.setState({selectedAvatarFile: frEvent.target.result});
                this.refs.avatar.setScale(1.0);
                this.refs.avatar.show();

            }.bind(this);

            reader.readAsDataURL(e.target.files[0]);
        }
    },


    bannerFileChanged: function (e) {
        if (e.target.value.toLowerCase().indexOf('.png') || e.target.value.toLowerCase().indexOf('.jpg')) {
            var reader = new FileReader();

            reader.onload = function (frEvent) {
                this.setState({selectedBannerFile: frEvent.target.result});
                this.refs.banner.setScale(1.0);
                this.refs.banner.show();

            }.bind(this);


            reader.readAsDataURL(e.target.files[0]);
        }
    },

    uploadProfilePic: function (dataUrl, type, size) {
        var jpg = dataUrl.split(',')[1];
        Api.uploadFile('/user/' + type + '?size=' + size, base64toBlob(jpg, 'image/jpeg')).then(
            function (result) {
            }
        );
    },

    saveAvatar: function (e) {
        this.uploadProfilePic(this.refs.avatar.getImage('image/jpg'), 'avatar', '');
        this.uploadProfilePic(this.refs.avatar.getResizedImage(128,128), 'avatar', 's');
        this.uploadProfilePic(this.refs.avatar.getResizedImage(32,32), 'avatar', 'xs');

        this.setState({avatarImage: this.refs.avatar.getImage('image/jpg')});

    },

    saveBanner: function (e) {
        this.uploadProfilePic(this.refs.banner.getImage('image/jpg'), 'banner', '');
        this.uploadProfilePic(this.refs.banner.getResizedImage(600,150), 'banner', 's');

        this.setState({bannerImage: this.refs.banner.getImage('image/jpg')});
    },

    render: function () {
        if (this.state.player) {
            return <span>

                    <Banner click={this.showBannerFilePicker}
                            image={this.state.bannerImage}
                            banner={true}
                            canEdit={this.state.editButtonEnabled}
                    />

                    <Avatar click={this.showAvatarFilePicker}
                            image={this.state.avatarImage}
                            canEdit={this.state.editButtonEnabled}
                    />

                    <div className="fb-profile-text">
                        <EditButtons canEdit={this.state.editButtonEnabled}
                                     onSave={this.clickSave}
                                     onEdit={this.clickEdit}
                                     onCancel={this.clickCancel}
                        />
                        <FollowButton leader={this.props.playerId}
                                      follower={this.state.loggedInUserId}
                                      onChange={this.followChange}
                        />
                        <div className="">
                            { this.renderPlayerInfo() }
                        </div>
                    </div>

                    <ImageEditor ref="avatar"
                                 title="Edit Avatar"
                                 width={250} height={250}
                                 image={this.state.selectedAvatarFile}
                                 save={this.saveAvatar}
                    />
                    <ImageEditor ref="banner"
                                 title="Edit Banner"
                                 save={this.saveBanner}
                                 width={1200} height={300}
                                 image={this.state.selectedBannerFile}
                    />


                    <input className="hidden-file" type="file" ref="avatarFile" accept=".png,.jpg"
                           onChange={this.avatarFileChanged}/>
                    <input className="hidden-file" type="file" ref="bannerFile" accept=".png,.jpg"
                           onChange={this.bannerFileChanged}/>

            </span>
        } else {
            return <div className="container full-height">

            </div>
        }
    },


    renderAchievementBadges: function () {
        return this.state.achievements.map(function (ach) {
            return <Badge key={ach.id} description={ach.description} colour={ach.colour} icon={ach.icon}/>
        });
    },

    onChange: function (event) {
        var player = this.state.player;
        player[event.target.id] = event.target.value;

        this.setState({player: player});
    },

    renderPlayerInfo: function () {
        if (this.state.editMode) {
            return <span >
                             <input type="text" placeholder="first name" onChange={this.onChange} id="firstName"
                                    value={this.state.player.firstName}/>
                             <input type="text" placeholder="surname" onChange={this.onChange} id="lastName"
                                    value={this.state.player.lastName}/>
                             <input type="text" placeholder="nickname" onChange={this.onChange} id="nickName"
                                    value={this.state.player.nickName}/>
                             <input type="text" placeholder="bio" onChange={this.onChange} id="bio"
                                    value={this.state.player.bio}/>
                </span>
        } else {
            return <span><h1>{this.state.player.firstName} {this.state.player.lastName}</h1>
                    <h4>{this.state.player.nickName}</h4>
                <p>{this.state.player.bio}</p></span>
        }
    },

    renderFollowing: function () {

        if (this.state.following.length == 0) {
            return null;
        }

        var followingList = this.state.following.map(function (leader) {
            return <DriverThumb key={leader.id} driver={leader.id} name={leader.firstname + ' ' + leader.lastname}/>
        });

        return <span>
                    <div className="profile-detail">
                        <h2>Following</h2>
                    </div>
                    <div className="row stats-box">
                        { followingList }
                    </div>
        </span>;
    },

    renderFollowers: function () {
        if (this.state.followers.length == 0) {
            return null;
        }

        var leaderList = this.state.followers.map(function (follower) {
            return <DriverThumb key={follower.id} driver={follower.id} name={follower.firstname + ' ' + follower.lastname}/>
        });

        return <span>
                    <div className="profile-detail">
                        <h2>Followers</h2>
                    </div>
                    <div className="row stats-box">
                        { leaderList }
                    </div>
        </span>;
    }
});
