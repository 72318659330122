var React = require("react");
var DriversTable = require('../components/drivers_table');

module.exports = React.createClass({

    render: function () {
        return <DriversTable
                    title="Near Drivers (Points)"
                    driversList={this.props.nearDrivers}
                    noMoreButton={true}
                    headers={ ['Position', 'Driver', 'Points']}
                    driverId={this.props.driverId}
                    dataValue="value"
        />
    }

});