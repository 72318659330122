var React = require("react");
var moment = require("moment");
var _ = require('lodash');
var Link = require('react-router').Link;
var Config = require('../config');

var Laptime = require('../components/laptime');

module.exports = React.createClass({

    render: function () {
        return <span>
                <h1>{this.props.laps.name}</h1>
                <table className="table table-striped">
                    <thead>
                    <tr>
                        {this.renderHeaders()}
                    </tr>
                    </thead>
                    <tbody>
                    { this.renderLaptimes() }
                    </tbody>
                </table>
        </span>
    },

    renderLaptimes: function () {
        return this.props.laps.layouts.map(function (layout) {
            return <tr key={layout.trackId} className={ layout.playerId && (layout.playerId == this.props.driverId) ? 'highlight-row' : ''}>
                <td>{layout.name}</td>
                { this.renderDriverName(layout) }
                <td><Laptime time={layout.time}/></td>
                { this.renderWorldRecord(layout.trackId) }
                <td title={ this.getDate(layout.timestamp) }>{ this.getFromNow(layout.timestamp) }</td>
            </tr>
        }.bind(this))
    },

    renderHeaders: function () {
        var headers = ['Track'];
        if (this.props.showDriver) headers.push('Driver');
        headers.push('Laptime');
        if (this.props.worldRecords) headers.push('WR');
        headers.push('Date');

        return headers.map(function (header, index) {
            return <th key={index}>{header}</th>
        });
    },

    renderDriverName: function (driver) {
        if (this.props.showDriver) {
            return <td><Link to={'/profile/' + driver.playerId}><img style={ {marginLeft: '-10', marginRight:'5'} }
                                                                     src={Config.apiUrl + '/user/avatar/' + driver.id + "?size=xs"}/>{driver.driverName}
            </Link></td>
        }
    },

    renderWorldRecord: function (trackId) {
        if (this.props.worldRecords) {
            var wr = _.findWhere(this.props.worldRecords, {trackId: trackId});

            if (wr) {
                return <td title={wr.name}><Link to={'/profile/' + wr.playerId}><Laptime time={wr.time}/></Link></td>
            }
        }
    },

    getDate: function (timestamp) {
        if (timestamp != "0000-00-00 00:00:00") {
            return moment(timestamp).calendar()
        }
    },

    getFromNow: function (timestamp) {
        if (timestamp != "0000-00-00 00:00:00") {
            return moment(timestamp).fromNow();
        }
    }
});