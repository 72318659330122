var Reflux = require('reflux');

var Actions = require('../actions');
var Api = require('../utils/api');

module.exports = Reflux.createStore({

    listenables: [Actions],

    getRankLeaderBoard: function () {
        return Api.get('/stats/rankleaderboard').then(function (result) {
            if (result && result.status == 'error') {
                this.trigger('getRankLeaderBoardFailed', result.message);
            } else {
                this.trigger('getRankLeaderBoardResult', result);
            }
        }.bind(this));
    },


    getPointsLeaderBoard: function () {
        return Api.get('/stats/pointsleaderboard').then(function (result) {
            if (result && result.status == 'error') {
                this.trigger('getPointsLeaderBoardFailed', result.message);
            } else {
                this.trigger('getPointsLeaderBoardResult', result);
            }
        }.bind(this));
    },

    getWorldRecords: function () {
        if (this.wrs){
            this.trigger('getWorldRecordsResult', this.wrs);
            return;
        }

        return Api.get('/stats/wrlist').then(function (result) {
            if (result && result.status == 'error') {

                this.trigger('getWorldRecordsFailed', result.message);
            } else {
                this.wrs = result;
                this.trigger('getWorldRecordsResult', result);
            }
        }.bind(this));
    },

    getNearPointDrivers: function(playerId){
        return Api.get('/stats/near/pointsrank/' + playerId).then(function (result) {
            if (result && result.status == 'error') {
                this.trigger('getNearPointDriversFailed', result.message);
            } else {
                this.trigger('getNearPointDriversResult', result);
            }
        }.bind(this));
    }


});