var React = require('react');

module.exports = React.createClass({

    render: function () {
        return <span><div className="panel panel-default">
            <div className="panel-heading">
                <h2 className="panel-title">Bespoke Physics Engine</h2>
            </div>
            <div className="panel-body">
                <h4>Constraint-based accuracy</h4>
                The vehicle physics engine uses a constraint-based approach which ensures stability and accuracy. This
                allows us, for example to place suspension rods with millimetre accuracy when given real-life car data.

                <h4>Tyre Model</h4>
                The tyre model takes into consideration the vertical force on the wheel (load), the pressure of the
                tyre, the camber and can accurately model the deflection of the tyre on the road. Also heat and wear
                will be modelled before ORC is released.

                <h4>Engine</h4>
                The engine is also part of the constraint model, you'll notice that when you rev the engine, some of the
                torque is transferred into the chassis causing it to rock. This kind of tiny effect is something that is
                automatically a feature of constraint-based system. It is a small thing but a combination of these small
                details will add to the overall realism of the simulator.

                <h4>Aerodynamics</h4>
                We have an accurate aero map for the current car we have modelled. This map gives us accurate downforce
                and drag data for any wing angle.

                <h4>Suspension</h4>
                As mentioned above, the constraint based system allows us to place suspension rods in the same place as
                the real car. This takes the guesswork out of modelling how the wheel will move when under load. With
                the constraints in place the real forces upon the wheel can be found accurately and the movement of the
                wheel will just be like the real thing, giving very accurate handling.
            </div>
        </div>

        <div className="panel panel-default">
            <div className="panel-heading">
                <h2 className="panel-title">Detailed Force Feedback</h2>
            </div>
            <div className="panel-body">
                The physics engine feeds directly into the force feedback system. This means you will get all the forces
                generated from the tyre directly to your wheel, you can feel the grip starting to go if you turn too
                hard or
                feel the steering get light going over the crest of a hill. We really recommend a force feedback wheel
                to get
                the most out of ORC.
            </div>
        </div>
        </span>
    }
});