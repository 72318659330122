var React = require('react');

module.exports = React.createClass({

    render: function () {
        return <span><div className="panel panel-default">
            <div className="panel-heading">
                <h2 className="panel-title">Video</h2>
            </div>
            <div className="panel-body">
                Here is a clip of the suspension in ORC in action
                <br/><br/>
                <center><iframe width="840" height="473" src="https://www.youtube.com/embed/elvBn6GPcLE" frameBorder="0" allowFullScreen="true"></iframe></center>
            </div>
        </div>


        </span>
    }
});