var React = require('react');
var validator = require('validator');
var Validation = require('react-validation');
var Reflux = require('reflux');

var Footer = require('../components/footer');
var Actions = require('../actions');
var LoginStore = require('../stores/login-store');
var createHashHistory = require('history/lib/createHashHistory');

const history = createHashHistory();

// Extend Validation with custom rules
Validation.extendErrors({
    isRequired: {
        className: 'has-error',
        message: 'required',
        rule: function (value) {
            return Boolean(validator.trim(value));
        }
    },
    isEmail: {
        className: 'has-error',
        // validator already has strong email-pattern, so we don't have to extend it by custom
        message: 'Invalid e-mail'
    }
});

var locations = ['Afghanistan', 'Albania', 'Algeria', 'American Samoa', 'Andorra', 'Angola', 'Anguilla', 'Antarctica',
    'Antigua and Barbuda', 'Argentina', 'Armenia', 'Aruba', 'Australia', 'Austria', 'Azerbaijan', 'Bahamas', 'Bahrain', 'Bangladesh',
    'Barbados', 'Belarus', 'Belgium', 'Belize', 'Benin', 'Bermuda', 'Bhutan', 'Bolivia', 'Bosnia and Herzegovina',
    'Botswana', 'Bouvet Island', 'Brazil', 'British Indian Ocean Territory', 'Brunei Darussalam', 'Bulgaria',
    'Burkina Faso', 'Burundi', 'Cambodia', 'Cameroon', 'Canada', 'Cape Verde', 'Cayman Islands', 'Central African Republic',
    'Chad', 'Chile', 'China', 'Christmas Island', 'Cocos Islands', 'Colombia', 'Comoros', 'Congo', 'Congo, Democratic Republic of the',
    'Cook Islands', 'Costa Rica', 'Cote d\'Ivoire', 'Croatia', 'Cuba', 'Cyprus', 'Czech Republic', 'Denmark', 'Djibouti', 'Dominica',
    'Dominican Republic', 'Ecuador', 'Egypt', 'El Salvador', 'England', 'Equatorial Guinea', 'Eritrea', 'Estonia', 'Ethiopia',
    'Falkland Islands', 'Faroe Islands', 'Fiji', 'Finland', 'France', 'French Guiana', 'French Polynesia', 'Gabon', 'Gambia',
    'Georgia', 'Germany', 'Ghana', 'Gibraltar', 'Greece', 'Greenland', 'Grenada', 'Guadeloupe', 'Guam', 'Guatemala', 'Guinea',
    'Guinea-Bissau', 'Guyana', 'Haiti', 'Heard Island and McDonald Islands', 'Honduras', 'Hong Kong', 'Hungary', 'Iceland',
    'India', 'Indonesia', 'Iran', 'Iraq', 'Ireland', 'Israel', 'Italy', 'Jamaica', 'Japan', 'Jordan', 'Kazakhstan', 'Kenya',
    'Kiribati', 'Kuwait', 'Kyrgyzstan', 'Laos', 'Latvia', 'Lebanon', 'Lesotho', 'Liberia', 'Libya', 'Liechtenstein', 'Lithuania',
    'Luxembourg', 'Macao', 'Madagascar', 'Malawi', 'Malaysia', 'Maldives', 'Mali', 'Malta', 'Marshall Islands', 'Martinique',
    'Mauritania', 'Mauritius', 'Mayotte', 'Mexico', 'Micronesia', 'Moldova', 'Monaco', 'Mongolia', 'Montenegro', 'Montserrat',
    'Morocco', 'Mozambique', 'Myanmar', 'Namibia', 'Nauru', 'Nepal', 'Netherlands', 'Netherlands Antilles', 'New Caledonia',
    'New Zealand', 'Nicaragua', 'Niger', 'Nigeria', 'Norfolk Island', 'North Korea', 'Northern Ireland', 'Norway', 'Oman',
    'Pakistan', 'Palau', 'Palestinian Territory', 'Panama', 'Papua New Guinea', 'Paraguay', 'Peru', 'Philippines', 'Pitcairn',
    'Poland', 'Portugal', 'Puerto Rico', 'Qatar', 'Romania', 'Russian Federation', 'Rwanda', 'Saint Helena', 'Saint Kitts and Nevis',
    'Saint Lucia', 'Saint Pierre and Miquelon', 'Saint Vincent and the Grenadines', 'Samoa', 'San Marino', 'Sao Tome and Principe',
    'Saudi Arabia', 'Scotland', 'Senegal', 'Serbia', 'Seychelles', 'Sierra Leone', 'Singapore', 'Slovakia', 'Slovenia',
    'Solomon Islands', 'Somalia', 'South Africa', 'South Georgia', 'South Korea', 'Spain', 'Sri Lanka', 'Sudan', 'Suriname',
    'Svalbard and Jan Mayen', 'Swaziland', 'Sweden', 'Switzerland', 'Syrian Arab Republic', 'Taiwan', 'Tajikistan', 'Tanzania',
    'Thailand', 'Macedonia', 'Timor-Leste', 'Togo', 'Tokelau', 'Tonga', 'Trinidad and Tobago',
    'Tunisia', 'Turkey', 'Turkmenistan', 'Tuvalu', 'Uganda', 'Ukraine', 'United Arab Emirates', 'United Kingdom', 'United States',
    'United States Minor Outlying Islands', 'Uruguay', 'Uzbekistan', 'Vanuatu', 'Vatican City', 'Venezuela', 'Vietnam',
    'Virgin Islands, British', 'Virgin Islands, U.S.', 'Wales', 'Wallis and Futuna', 'Western Sahara', 'Yemen', 'Zambia', 'Zimbabwe'];

function stringStartsWith(string, prefix) {
    return string.slice(0, prefix.length) == prefix;
}

module.exports = React.createClass({

    mixins: [Reflux.listenTo(LoginStore, 'onRegisterEvent')],

    getInitialState: function () {
        return {registered: false, hasError: false, message: ''};
    },

    onChange: function (event) {
        var stateObject = function () {
            var returnObj = {};
            returnObj[this.target.id] = this.target.value;
            return returnObj;
        }.bind(event)();

        if (stringStartsWith(event.target.id, 'password')) {
            this.checkPasswordsMatch();
        }

        this.setState(stateObject);
    },

    registerClick: function (event) {
        event.preventDefault();

        if (this.checkPasswordsMatch()) {
            Actions.userRegister(this.state);
        }
    },

    onRegisterEvent: function (event, data) {
        switch (event) {
            case 'registrationFailed':
                this.setState({hasError: true, message: data});
                break;
            case 'registrationSuccess':
                history.replaceState(null, '/');
                break;
        }
    },

    checkPasswordsMatch: function () {
        var password = this.refs.password;
        var passwordConfirm = this.refs.passwordConfirm;
        var hasValue = password.state.value && passwordConfirm.state.value;

        if (hasValue) {
            if (password.state.value === passwordConfirm.state.value) {
                password.hideError();
                passwordConfirm.hideError();
                return true;
            } else {
                password.showError('Passwords should match');
                passwordConfirm.showError('Passwords should match');
                return false;
            }
        }
    },

    render: function () {

        return <div className="container full-height">

            <div className="row">
                <div className="col-lg-12">
                    <h1 className="page-header">Register
                        <small> Alpha</small>
                    </h1>
                    <ol className="breadcrumb">
                        <li><a href="index.html">Home</a>
                        </li>
                        <li className="active">Register</li>
                    </ol>
                </div>
            </div>

            <div className="row">
                <div className="col-lg-12">
                    <img className="img-responsive" src="img/reflections.jpg" alt=""/>
                </div>
            </div>

            <div className="row">
                { this.renderRegistrationForm() }
            </div>

            <hr />

            <Footer/>
        </div>
    },


    renderRegistrationForm: function () {
        if (this.state.registered) {
            return <div><br/><br/>
                <div className="alert alert-success">
                    <strong>You have registered!</strong> Check your email to confirm your address
                </div>
            </div>
        }
        else {
            return <div className="col-md-8">
                <h3>Register for Online Racing Championship</h3>
                <br/><b>*NOTE*</b> You will receive an email after you register. Please confirm your address by
                clicking on the link in the email.
                <br/><br/>
                { this.renderError() }
                <Validation.Form name="sentMessage" id="contactForm">

                    <div className="control-group form-group">
                        <div className="controls">
                            <label>Email*</label>
                            <Validation.Input type="email" className="form-control" onChange={this.onChange}
                                              id="email" name="email" blocking='input' placeholder=""
                                              validations={[{rule: 'isRequired'}, {rule: 'isEmail'}]}/>
                        </div>
                    </div>
                    <div className="control-group form-group">
                        <div className="controls">
                            <label>First Name*</label>
                            <Validation.Input type="text" className="form-control" onChange={this.onChange}
                                              id="firstName" name="firstName" blocking='input' placeholder=""
                                              validations={[{rule: 'isRequired'}]}/>
                            <p className="help-block"></p>
                        </div>
                    </div>
                    <div className="control-group form-group">
                        <div className="controls">
                            <label>Last Name*</label>
                            <Validation.Input type="text" className="form-control" onChange={this.onChange}
                                              id="lastName" name="lastName" blocking='input' placeholder=""
                                              validations={[{rule: 'isRequired'}]}/>
                            <p className="help-block"></p>
                        </div>
                    </div>
                    <div className="control-group form-group">
                        <div className="controls">
                            <label>Nickname*</label>
                            <Validation.Input type="text" className="form-control" onChange={this.onChange}
                                              id="nickName" name="nickName" blocking='input' placeholder=""
                                              validations={[{rule: 'isRequired'}]}/>
                            <p className="help-block"></p>
                        </div>
                    </div>

                    <div className="control-group form-group">
                        <div className="controls">
                            <label>Password (min 6 characters)*</label>
                            <Validation.Input type="password" className="form-control" onChange={this.onChange}
                                              id="password" name="password" ref='password' blocking='input'
                                              placeholder=""
                                              validations={[{rule: 'isRequired'}]}/>
                        </div>
                    </div>

                    <div className="control-group form-group">
                        <div className="controls">
                            <label>Password Confirmation*</label>
                            <Validation.Input type="password" className="form-control" onChange={this.onChange}
                                              id="passwordConfirm" name="passwordConfirm" ref='passwordConfirm'
                                              blocking='input' placeholder=""
                                              validations={[{rule: 'isRequired'}]}/>
                        </div>
                    </div>

                    <div className="control-group form-group">
                        <div className="controls">
                            <label>Location*</label>
                            <Validation.Select name="location" id="location" defaultValue="1" className="form-control"
                                               onChange={this.onChange} placeholder="Select your location"
                                               blocking='input' placeholder="" validations={[{rule: 'isRequired'}]}>
                                <option disabled hidden value="1"> -- Select a location --</option>

                                {this.renderLocations()}

                            </Validation.Select>
                        </div>
                    </div>

                    <Validation.Button onClick={this.registerClick} ref='registerButton' blocking='button'
                                       value='Register'
                                       className="btn btn-primary"/>

                </Validation.Form>
            </div>
        }
    },

    renderError: function () {
        if (this.state.hasError) {
            return <div className="alert alert-danger" role="alert">
                <span className="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>
                <span className="sr-only">Error:</span> Failed to register : { this.state.message }
            </div>
        }
    },


    renderLocations: function () {
        var index = 1;
        return locations.map(function (location) {
            return <option key={index} value={ ++index } label={location}>{location}</option>;
        });
    }

});