var React = require("react");
var Reflux = require('reflux');

var Actions = require('../actions');
var StatsStore = require('../stores/stats-store');
var DriversTable = require('../components/drivers_table');

module.exports = React.createClass({

    mixins: [Reflux.listenTo(StatsStore, 'onStatsEvent')],

    getInitialState: function () {
        return {rankLeaders: []};
    },

    componentDidMount: function () {
        Actions.getRankLeaderBoard();
    },

    onStatsEvent: function (event, data) {
        if (event == 'getRankLeaderBoardResult') {
            this.setState({rankLeaders: data})
        }
    },

    render: function () {
        return <DriversTable
                    title="Top Drivers (ORC Rank)"
                    driversList={this.state.rankLeaders}
                    headers={ ['Position', 'Driver', 'Rank']}
                    dataValue="orcRank"
                    driverId={this.props.driverId}
        />
    }

});