require('whatwg-fetch'); // We just need to require it to make the fetch function available
var Cookie = require('react-cookie');

var RootUrl = require('../config').apiUrl;

var orcAuthToken = null;

var getTokenFromCookie = function () {
        orcAuthToken = Cookie.load('authToken');
};

module.exports = {

    getJsonHeaders: function () {
        var headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        };

        if (orcAuthToken) headers['x-access-token'] = orcAuthToken;
        return headers;
    },

    get: function(url){
        getTokenFromCookie();

        var headers = {};
        if (orcAuthToken) headers['x-access-token'] = orcAuthToken;

        return fetch(RootUrl + url, {
            headers: headers
        })
        .then(function(response){
           return response.json();
        });
    },

    post: function(url, data){
        getTokenFromCookie();

        var headers = this.getJsonHeaders();

        return fetch(RootUrl + url, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(data)
        }).then(function(response){
            return response.json();
        });
    },

    delete: function(url, data){
        getTokenFromCookie();

        var headers = this.getJsonHeaders();

        return fetch(RootUrl + url, {
            method: 'DELETE',
            headers: headers,
            body: JSON.stringify(data)
        }).then(function(response){
            return response.json();
        });
    },

    put: function(url, data){
        getTokenFromCookie();

        var headers = this.getJsonHeaders();

        return fetch(RootUrl + url, {
            method: 'PUT',
            headers: headers,
            body: JSON.stringify(data)
        }).then(function(response){
            return response.json();
        });
    },

    uploadFile: function(url, fileData){
        getTokenFromCookie();
        var data = new FormData();
        data.append('file', fileData);

        var headers = {
            'Accept': 'application/json'
        };

        if (orcAuthToken) headers['x-access-token'] = orcAuthToken;

        return fetch(RootUrl + url, {
            method: 'post',
            body: data,
            headers: headers
        }).then(function(response){
            return response.json();
        });
    },

    login: function(username, password){
        return this.post('/auth/authenticate', {email: username, password: password } );
    }

};