var Reflux = require('reflux');
var Cookie = require('react-cookie');

var Actions = require('../actions');
var Api = require('../utils/api');

module.exports = Reflux.createStore({

    listenables: [Actions],
    players: {},

    saveAuthToken: function (token) {
        this.logout();
        this.authToken = token;
        Cookie.save('authToken', this.authToken);

        this.trigger('loginSuccess', this.authToken);
    },

    userLogin: function(username, password){
        return Api.login(username, password).then(function success(response){
            if (response.token) {
                this.saveAuthToken(response.token);
            }else{
                this.loginFailed(response);
            }

        }.bind(this));
    },
    userRegister: function(user){
        Api.post('/user', user).then(function (response) {
            if (response.token) {
                this.saveAuthToken(response.token);
                this.trigger('registrationSuccess', response.msg);
            }else{
                this.trigger('registrationFailed', response.msg);
            }
        }.bind(this))
    },

    updateUser: function(user){
        Api.put('/user/' + user.id, user).then(function(response){
            if (response.status && response.status == 'success'){
                this.trigger('updateUserSuccess');
            }else{
                this.trigger('updateUserFail', response.message);
            }
        }.bind(this));
    },

    loginFailed: function (response) {
        console.log('Removing Cookie');
        Cookie.remove('authToken');
        this.trigger('loginFailed', response.message);
    },

    getMyDetails: function(){
        if (this.user){
            this.trigger('myDetails', this.user);
            return;
        }

        if ( Cookie.load('authToken') === undefined){
            this.trigger('noCookie');
            return;
        }

        return Api.get('/user/me').then(function (response){
            if (!response.id){
                // Failed - probably cookie is out of date
                this.loginFailed(response);
            }else{
                this.user = response;
                this.players[response.id] = response;
                this.trigger('myDetails', this.user);
            }
        }.bind(this));
    },

    getUserDetails: function(id){
        if (this.players[id]){
            this.trigger('userDetails', this.players[id]);
            return;
        }

        return Api.get('/user/' + id).then(function (response){
            if (!response.id){
                // Failed - probably cookie is out of date
                this.trigger('loginFailed', response.message);
            }else{
                this.players[response.id] = response;
                this.trigger('userDetails', response);
            }
        }.bind(this));
    },

    getPbsForPlayer: function(id){
        return Api.get('/stats/pbs/' + id).then(function (response){
            if (Array.isArray(response)){
                this.trigger('playerPbs', response);
            }else{
                this.trigger('playerPbsFailed', response);
            }
        }.bind(this));
    },

    getAuthToken: function(){
       if (this.authToken){
           this.trigger('authToken', this.authToken);
       }
    },

    logout: function(){
        Cookie.remove('authToken');
        this.authToken = null;
        this.user = null;
        this.trigger('logout');
    }

});