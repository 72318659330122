var React = require('react');
var Reflux = require('reflux');
var _ = require('lodash');
var Link = require('react-router').Link;
var LightBox = require('react-images');

var Actions = require('../actions');
var FacebookStore = require('../stores/facebook-store');

var Footer = require('../components/footer');

module.exports = React.createClass({

    mixins: [Reflux.listenTo(FacebookStore, 'onFacebookPicsLoaded')],

    getInitialState: function () {
        return {photos: [], album: {name: ""}, lightboxIsOpen: false, lightBoxImages: []}
    },

    componentDidMount: function () {
        Actions.getFacebookAlbum(this.props.routeParams.id);
        Actions.setCurrentAlbum(this.props.routeParams.id);
    },

    onFacebookPicsLoaded: function (event, data) {
        if (event == 'photosSuccess') {

            var lightBoxImages = data.map(function(img){
                return { src: img.images[0].source }
            });

            this.setState({photos: data, lightBoxImages:lightBoxImages});

        } else if (event == 'albumChanged') {
            this.setState({album: data });
        } else if (event == 'albumsSuccess') {
            Actions.setCurrentAlbum(this.props.routeParams.id);
        }
    },

    openLightBox: function(index, event){
        event.preventDefault();
        this.setState({
            currentImage: index,
            lightboxIsOpen: true
        });
    },

    closeLightbox : function() {
        this.setState({
            currentImage: 0,
            lightboxIsOpen: false
        });
    },

    gotoPrevious : function() {
        this.setState({
            currentImage: this.state.currentImage - 1
        });
    },

    gotoNext : function() {
        this.setState({
            currentImage: this.state.currentImage + 1
        });
    },

    render: function () {
        return <span style={{"height" : "100%"}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <h1 className="page-header">Screenshots
                                    <small> { this.state.album.name }</small>
                                </h1>
                                <ol className="breadcrumb">
                                    <li><Link to="/">Home</Link>
                                    </li>
                                    <li><Link to="/screens">Albums</Link>
                                    </li>
                                    <li className="active">{ this.state.album.name } </li>
                                    { this.renderDescription() }
                                </ol>

                            </div>

                        </div>

                        { this.renderPics() }
                        <LightBox
                            currentImage={this.state.currentImage}
                            images={this.state.lightBoxImages}
                            isOpen={this.state.lightboxIsOpen}
                            onClickPrev={this.gotoPrevious}
                            onClickNext={this.gotoNext}
                            onClose={this.closeLightbox}
                            width={1920}
                            height={1080}
                        />

                        <Footer/>
                    </div>

            </span>;
    },
    renderPics: function () {

        var rows = _.chunk(this.state.photos, 4);

        return rows.map(function (row, i) {

            return <div className="row" key={i}>
                { this.renderPicsRow(row, i) }
            </div>;
        }.bind(this));
    },
    renderPicsRow: function (row, rowIndex) {

        return row.map(function (pic, i) {
            return <div className="col-md-3 img-portfolio" key={pic.id}>
                <a href="#" onClick={this.openLightBox.bind(null, rowIndex*4 + i)} target="_blank">
                    <img className="img-responsive img-hover"
                         src={pic.source}/>
                    <div className="photo-label">{pic.name}</div>
                </a>
            </div>
        }.bind(this))
    },

    renderDescription: function () {
        if (this.state.album && this.state.album.description) {
            return <span>
                <br/><br/>
                <p>
                    <b>{ this.state.album.description }</b>
                </p>
            </span>
        }
    }


});