var React = require('react');
var Reflux = require('reflux');
var _ = require('lodash');

var Actions = require('../actions');
var FriendsStore = require('../stores/friends-store');
var LoginStore = require('../stores/login-store');

module.exports = React.createClass({

    mixins: [
        Reflux.listenTo(FriendsStore, 'onEvent' ),
        Reflux.listenTo(LoginStore, 'onEvent' )
    ],

    getInitialState : function(){
        var canFollow = this.checkCanFollow(this.props.leader, this.props.follower);

        if (canFollow) {
            Actions.checkFollowing(this.props.leader);
        }

        return {
            isFollowing : false,
            gettingInfo: canFollow,
            canFollow: canFollow
        }
    },

    componentWillReceiveProps: function(nextProps, prevProps){
        if (! _.isEqual(nextProps,prevProps) && !this.state.gettingInfo) {

            var canFollow = this.checkCanFollow(nextProps.leader, nextProps.follower);

            if (canFollow) {
                Actions.checkFollowing(nextProps.leader);
            }

            this.setState({
                isFollowing: false,
                canFollow: canFollow,
                gettingInfo: canFollow
            });
        }
    },

    onEvent: function(event, data){
        switch(event){
            case 'canFollowResult':
                this.setState({isFollowing : data.following, gettingInfo: false});
                break;
            case 'logout':
                this.setState({canFollow: false});
                break;
            case 'followResult':
                this.setState({isFollowing : true, gettingInfo: false});
                if (this.props.onChange) this.props.onChange('followed');
                break;
            case 'unfollowResult':
                this.setState({isFollowing : false, gettingInfo: false});
                if (this.props.onChange) this.props.onChange('unfollowed');
                break;
        }
    },

    checkCanFollow: function (leader, follower) {
        return (leader != follower) && leader && follower;
    },


    render: function () {

        if (!this.state.canFollow){
            return <span></span>
        }

        if (this.state.gettingInfo){
            return <button className="btn btn-primary" disabled>Waiting <i className="fa fa-spin fa-spinner"/></button>
        }
        else
        if (this.state.isFollowing) {
            return <button onClick={this.clickUnfollow} className="btn btn-primary">Unfollow</button>
        }else{
            return <button onClick={this.clickFollow} className="btn btn-primary">Follow</button>
        }
    },

    clickFollow: function(){
        this.setState({gettingInfo: true});
        Actions.follow(this.props.leader);
    },

    clickUnfollow: function(){
        this.setState({gettingInfo: true});
        Actions.unfollow(this.props.leader);
    }


});