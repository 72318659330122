var React = require('react');
var Footer = require('../components/footer');
var Physics = require('../components/physics-feature');
var Achievements = require('../components/achievements-feature');
var Networking = require('../components/networking-feature');

module.exports = React.createClass({

    render: function () {
        return <div className="container full-height">

            <div className="row">
                <div className="col-lg-12">
                    <h2 className="page-header">Features
                        <small> Alpha</small>
                    </h2>
                    <ol className="breadcrumb">
                        <li><a href="index.html">Home</a>
                        </li>
                        <li className="active">Features</li>
                    </ol>
                </div>
            </div>

            <div className="row">
                <div className="col-lg-12">
                    <img className="img-responsive" src="img/physics.jpg" alt="" />
                </div>
            </div>

            <div className="row">
                <div className="col-lg-12">
                    
                    <br />
                    <Physics/>

                    <img className="img-responsive" src="img/forcefeedback.jpg" />
                    <br />

                    <Networking/>

                    <div className="panel panel-default">
                        <div className="panel-heading">
                            <h2 className="panel-title">Download Custom Setups and Skins</h2>
                        </div>
                        <div className="panel-body">
                            Custom paint jobs or "skins" for your competitors cars downloaded automatically when you join the server allowing you to see their weird and wonderful creations.  You can also download a competitors car setup with a single click and try it out.  Is it skill or is it setup that is making them quick?!
                        </div>
                    </div>

                   <Achievements/>

                    <div className="panel panel-default">
                        <div className="panel-heading">
                            <h2 className="panel-title">Graphics</h2>
                        </div>
                        <div className="panel-body">
                            We are using the excellent <a href="http://ogre3d.org" target="_blank">Ogre3d</a> graphics engine along with our own lighting system.  Our lighting system brings the racing environment to life.  With real-time reflections and self-shadowing on the car and a glimmering track in front of you it feels like you are at the race-track.
                        </div>
                    </div>

                    <img className="img-responsive" src="img/reflections.jpg" />

                    <div className="panel panel-default">
                        <div className="panel-heading">
                            <h2 className="panel-title">Sounds</h2>
                        </div>
                        <div className="panel-body">
                            We use <a href="http://fmod.org" target="_blank">fmod</a> which supports fully 3d positional sounds allowing you to feel like you are in the middle of the action.
                        </div>
                    </div>

                    <div className="panel panel-default">
                        <div className="panel-heading">
                            <h2 className="panel-title">Driver Aids</h2>
                        </div>
                        <div className="panel-body">
                            Love realism, but want to be broke in gently?  No problem!  We have a number of realistic driver aids that have been modelled on real-life equivalents.  We have traction control, which will help you stop spinning under acceleration.  Anti-Lock Brakes (ABS) which helps when braking for a corner.  Stability Control which helps reduce spinning during cornering.  We also give the option of turning off the tyre heat while you are getting started.
                        </div>
                    </div>


                </div>
            </div>

            <hr />

            <Footer/>
        </div>
    }

});