var React = require('react');
var Reflux = require('reflux');
var Link = require('react-router').Link;

var Actions = require('../actions');
var StatsStore = require('../stores/stats-store');
var Footer = require('../components/footer');
var PlayerHeader = require('../components/profile-player-header');
var ProfilePoints = require('../components/profile-points');

module.exports = React.createClass({

    mixins: [
        Reflux.listenTo(StatsStore, 'onEvent')
    ],

    getInitialState: function () {

        return {
            nearDrivers: []
        };
    },

    componentDidMount: function () {
        this.callActions(this.props.params.id);
    },

    componentWillReceiveProps: function (nextProps) {
        this.callActions(nextProps.params.id);
    },

    callActions: function (id) {
        Actions.getNearPointDrivers(id);
    },


    onEvent: function (event, data) {
        switch (event) {
            case 'getNearPointDriversResult':
                this.setState({nearDrivers: data});
                break;
        }

    },

    render: function () {
        if (this.state.nearDrivers) {
            return <div className="container full-height ">
                <div className="fb-profile profile-border">

                    <PlayerHeader playerId={this.props.params.id}/>

                    <div className="row">
                        <div className="col-lg-12">
                            <ol className="breadcrumb profile-bread">
                                <li><a href="index.html">Home</a>
                                </li>
                                <li><Link to={'/profile/' + this.props.params.id}>Profile</Link>
                                </li>
                                <li className="active">Points</li>
                            </ol>
                        </div>
                    </div>

                    <ProfilePoints nearDrivers={this.state.nearDrivers} driverId={this.props.params.id} />

                </div>
                <Footer />
            </div>
        } else {
            return <div className="container full-height">

            </div>
        }
    }

});
