
module.exports = {
  toMinSecs: function(secsIn){
      secsIn =  parseFloat(secsIn);
      var mins = ~~(secsIn / 60);
      var secs = secsIn % 60;

      if (mins) {
          return mins + ":" + secs.toFixed(3);
      }else{
          return secs.toFixed(3);
      }
  }
};