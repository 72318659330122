var React = require('react');

module.exports = React.createClass({

    render: function () {
        return <span> <div className="panel panel-default">
            <div className="panel-heading">
                <h2 className="panel-title">Networking</h2>
            </div>
            <div className="panel-body">
                <h4>Advanced Physics-based smoothing system</h4>
                Constraints are used to help make the car appear smooth even with fairly high ping times.  This reduces the effect of lag and means that you can closely compete with drivers from all over the world.
            </div>
        </div>
        </span>
    }
});