var React = require('react');
var NavBar = require('./nav-bar');

module.exports = React.createClass({
    render: function (){

        return <div className="full-height">
            <NavBar />
            {this.props.children}
        </div>
    }
});