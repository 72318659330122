var Reflux = require('reflux');

var Actions = require('../actions');
var Api = require('../utils/api');

module.exports = Reflux.createStore({

    listenables: [Actions],

    getNews: function(){
        return Api.get('/news').then(function (response){
            if (!response.news){
                // Failed - probably cookie is out of date
                this.trigger('newsUpdateFailed', response.message);
            }else{
                this.news = response;
                this.trigger('newsUpdated', this.news);
            }
        }.bind(this));
    },
    getNewsItem: function(id){
        return Api.get('/news/' + id).then(function (response){
            if (!response.id){
                // Failed - probably cookie is out of date
                this.trigger('newsItemFailed', response);
            }else{
                this.news = response;
                this.trigger('newsItem', this.news);
            }
        }.bind(this));
    },

    updateNewsItem: function(id, newsItem){
        return Api.put('/news/' + id, newsItem).then(function (response){
            if (!response.status || response.status != "SUCCESS"){
                // Failed - probably cookie is out of date
                this.trigger('newsItemUpdateFailed', response);
            }else{
                this.trigger('newsItemUpdated');
            }
        }.bind(this));
    },

    addNewsItem: function(newsItem){
        return Api.post('/news', newsItem).then(function (response){
            if (!response.status || response.status != "SUCCESS"){
                // Failed - probably cookie is out of date
                this.trigger('newsItemCreateFailed', response);
            }else{
                this.trigger('newsItemCreated');
            }
        }.bind(this));
    }



});