var React = require('react');
var Reflux = require('reflux');
var Link = require('react-router').Link;
var ReactQuill = require('react-quill');

var Actions = require('../actions');
var NewsStore = require('../stores/news-store');

var Footer = require('../components/footer');
var NewsItem = require('../components/news-item');


module.exports = React.createClass({
    mixins: [
        Reflux.listenTo(NewsStore, 'onReceiveNews'),
    ],


    getInitialState: function () {
        return {newsItem: {title: "", description: "", summary: "", pic: null, publish: false}, player: null};
    },

    componentDidMount: function () {
        if (this.props.routeParams.id) { // We are editing - retreive the newsItem
            Actions.getNewsItem(this.props.routeParams.id);
        } else {
            this.setState({newsItem: {title: "", description: "", summary: "", pic: null, publish: false}})
        }
    },

    onReceiveNews: function (event, newsData) {
        if (event == 'newsItem') {
            this.setState({newsItem: newsData})
        }
        if (event == 'newsItemUpdated') {

        }
    },

    onDescChange: function (value) {
        if (this.state.newsItem) {
            var newsItem = this.state.newsItem;
            newsItem.description = value;
            this.setState({newsItem: newsItem});
        }
    },

    textValueChange: function(event){
        if (this.state.newsItem) {
            var newsItem = this.state.newsItem;
            newsItem[event.target.id] = event.target.value;
            this.setState({newsItem: newsItem});
        }
    },

    saveItem: function () {
        if (this.props.routeParams.id && this.state.newsItem) {
            Actions.updateNewsItem(this.props.routeParams.id, this.state.newsItem);
        } else {
            Actions.addNewsItem(this.state.newsItem);
        }
    },

    render: function () {
        return <div className="container full-height">

            <div className="row">
                <div className="col-lg-12">
                    <h1 className="page-header">News

                    </h1>
                    <ol className="breadcrumb">
                        <li>
                            <Link to="/">Home</Link>
                        </li>
                        <li>
                            <Link to="/news">News</Link>
                        </li>
                        <li className="active">{this.renderBreadcrumb()}</li>
                    </ol>
                </div>
            </div>

            <div className="row">
                <div className="col-lg-12">
                        <div className="form-group">
                            <label for="usr">Title:</label>
                            <input type="text" className="form-control" id="title" value={this.state.newsItem.title} onChange={this.textValueChange}/>
                        </div>
                        <div className="form-group">
                            <label for="summary">Summary:</label>
                            <input type="text" className="form-control" id="summary" value={this.state.newsItem.summary} onChange={this.textValueChange}/>
                        </div>

                        <div className="form-group">
                            <label for="pic">Pic Url:</label>
                            <input type="text" className="form-control" id="pic" value={this.state.newsItem.pic} onChange={this.textValueChange}/>
                        </div>

                        <div className="form-group">
                            <label for="summary">Description:</label>
                            { this.renderEditor() }
                        </div>

                        <div className="btn btn-default" onClick={this.saveItem}>Save</div>
                </div>
            </div>

            <h1>Preview</h1>
            { this.renderNewsItem() }
            <hr />

            <Footer/>
        </div>
    },

    renderNewsItem: function () {
        var post = this.state.newsItem;
        if (post) {
            return <NewsItem key={post.id} news={post}/>
        }
    },

    renderBreadcrumb: function () {
        var post = this.state.newsItem;
        if (post) {
            return <span>{post.title}</span>;
        }
    },

    renderEditor: function () {
        if (this.state.newsItem) {
            return <div className="editor"><ReactQuill theme="snow" value={this.state.newsItem.description} onChange={this.onDescChange}/></div>
        }
    }

});