var React = require('react');
var Reflux = require('reflux');
var _ = require('lodash');
var Link = require('react-router').Link;

var Actions = require('../actions');
var FacebookStore = require('../stores/facebook-store');

var Footer = require('../components/footer');

module.exports = React.createClass({

    mixins: [Reflux.listenTo(FacebookStore, 'onFacebookPicsLoaded')],

    getInitialState: function () {
        return {albums: []}
    },

    componentDidMount: function () {
        Actions.getFacebookAlbums();
    },

    onFacebookPicsLoaded: function (event, data) {
        if (event == 'albumsSuccess') {
            this.setState({albums: data});
        }
    },

    render: function () {
        return <span style={{"height" : "100%"}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <h1 className="page-header">Media
                                    <small> Albums</small>
                                </h1>
                                <ol className="breadcrumb">
                                    <li><a href="index.html">Home</a>
                                    </li>
                                    <li className="active">Albums</li>
                                </ol>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-12">
                                <h2 className="page-header">Screenshots</h2>
                            </div>
                            { this.renderPics() }
                        </div>

                        <div className="row">
                            <div className="col-lg-12">
                                <h2 className="page-header">Trailer</h2>
                            </div>
                        </div>
                        <div className="embed-responsive embed-responsive-16by9">
                            <iframe className="embed-responsive-item" src="https://www.youtube.com/embed/dNUTi7fnS74" ></iframe>
                        </div>

                        <Footer/>
                    </div>

            </span>;
    },
    renderPics: function () {

        var rows = _.chunk(this.state.albums, 4);

        return rows.map(function (row,i ) {

            return <div className="row" key={i}>
                { this.renderPicsRow(row) }
            </div>;
        }.bind(this));
    },
    renderPicsRow: function (row) {

        return row.map(function (album) {
            return <div className="col-md-3 img-portfolio" key={album.id}>
                <Link to={'/album/' + album.id}>
                    <img className="img-responsive img-hover"
                         src={'https://graph.facebook.com/' + album.cover_photo.id + '/picture'}/>
                    <div className="photo-label">{album.name}</div>
                </Link>
            </div>
        }.bind(this))
    }




});