var React = require('react');
var ReactRouter = require('react-router');
var Router = ReactRouter.Router;
var Route = ReactRouter.Route;
var IndexRoute = ReactRouter.IndexRoute;
var Container = require('./components/container');
var About = require('./pages/about');
var Downloads = require('./pages/downloads');
var MainMenu = require('./pages/homepage');
var Screens = require('./pages/screens');
var Album = require('./pages/album');
var News = require('./pages/news');
var Features = require('./pages/features');
var FeatureDetail = require('./pages/feature-detail');
var Register = require('./pages/register');
var NewsDetail = require('./pages/news-detail');
var NewsEdit = require('./pages/news-edit');
var Forgot = require('./pages/forgot');
var Reset = require('./pages/resetpassword');
var Profile = require('./pages/profile');
var PlayerPoints = require('./pages/playerpointsrank');

module.exports = (
    <Router >
        <Route path="/" component={Container}>
            <Route path="/about" component={About}/>
            <Route path="/downloads" component={Downloads}/>
            <Route path="/media" component={Screens}/>
            <Route path="/news" component={News}/>
            <Route path="/news/create" component={NewsEdit}/>
            <Route path="/news/:id" component={NewsDetail}/>
            <Route path="/news/edit/:id" component={NewsEdit}/>
            <Route path="/features" component={Features}/>
            <Route path="/features/:feature" component={FeatureDetail}/>
            <Route path="/register" component={Register}/>
            <Route path="/forgot" component={Forgot}/>
            <Route path="/reset" component={Reset}/>
            <Route path="/album/:id" component={Album}/>
            <Route path="/profile/:id" component={Profile}/>
            <Route path="/points/:id" component={PlayerPoints}/>

            <IndexRoute component={MainMenu}/>
        </Route>
    </Router>
);