var React = require('react');
var Reflux = require('reflux');
var Link = require('react-router').Link;

var Actions = require('../actions');
var NewsStore = require('../stores/news-store');
var LoginStore = require('../stores/login-store');

var Footer = require('../components/footer');
var NewsItem = require('../components/news-item');




module.exports = React.createClass({
    mixins: [
        Reflux.listenTo(NewsStore, 'onReceiveNews' ),
        Reflux.listenTo(LoginStore, 'onReceivePlayerData' )
    ],


    getInitialState : function(){
        return { newsItem: null, player: null };
    },

    componentDidMount : function(){
        Actions.getMyDetails();
        Actions.getNewsItem(this.props.routeParams.id);
    },

    onReceiveNews: function(event, newsData){
        if (event == 'newsItem') {
            this.setState({newsItem: newsData})
        }
    },

    onReceivePlayerData: function(event, playerData){
        if (event == 'myDetails'){
            this.setState({ player: playerData });
        }else if (event == 'logout'){
            this.setState({ player: null });
        }
    },


    render: function () {
        return <div className="container full-height">

            <div className="row">
                <div className="col-lg-12">
                    <h1 className="page-header">News

                    </h1>
                    <ol className="breadcrumb">
                        <li>
                            <Link to="/">Home</Link>
                        </li>
                        <li>
                            <Link to="/news">News</Link>
                        </li>
                        <li className="active">{this.renderBreadcrumb()}</li>
                    </ol>
                </div>
            </div>

            { this.renderNewsItem() }


            <hr />

            <Footer/>
        </div>
    },

    renderNewsItem: function(){
        var post = this.state.newsItem;
        var showEdit = this.isAdminUser() && this.state.newsItem
        if (post){
            return <NewsItem key={post.id} news={post} showEdit={showEdit}/>
        }
    },

    renderBreadcrumb: function(){
        var post = this.state.newsItem;
        if (post){
            return <span>{post.title}</span>;
        }
    },

    isAdminUser: function(){
        return (this.state.player && this.state.player.userType.toLowerCase() == 'admin');
    },

    renderCreateNewsButton: function(){
        if (this.isAdminUser() && this.state.newsItem){
            return <Link to="/news/edit"><i className="fa fa-newspaper-o"/> Create new news item </Link>
        }
    }

});