var React = require('react');
var Link = require('react-router').Link;
var Moment = require('moment');

module.exports = React.createClass({

    getInitialState: function(){
        var headerNumber = Math.floor((Math.random() * 10) + 1);
        return ({temporaryPic: 'img/header' + headerNumber + '.jpg'});
    },

    render: function () {
        var post = this.props.news;

        return <div className="row">
            <div className="col-lg-12">
                <h2>
                    <Link to={'/news/' + post.id}>{ post.title }</Link> { this.renderEditButton() }
                </h2>
                <p className="lead">
                    by <a href="#">Ash McConnell</a>
                </p>
                <p title={post.date}><i className="fa fa-clock-o"></i> {Moment(post.date).fromNow()}</p>
                <hr/>
                <Link to={'/news/' + post.id}>
                    { this.renderHeaderPic() }
                </Link>
                <hr/>

                <div dangerouslySetInnerHTML={{__html: post.description}} ></div>
                <hr/>
            </div>
        </div>
    },

    renderHeaderPic: function(){
        if (this.props.news.pic){
            return <img className="img-responsive img-hover" src={this.props.news.pic} />
        }else{
            return <img className="img-responsive img-hover" src={this.state.temporaryPic} />
        }
    },

    renderEditButton: function(){
        if (this.props.showEdit){
            return <Link to={"/news/edit/" + this.props.news.id} ><i className="fa fa-edit small"></i> </Link>
        }
    }
});