var React = require('react');
var Link = require('react-router').Link;

var Actions = require('../actions');

module.exports = React.createClass({

    getInitialState: function () {
        return {
            username: '',
            password: ''
        };
    },

    handleLogin: function (event) {
        event.preventDefault();
        Actions.userLogin(this.state.username, this.state.password);
    },

    handleChange: function (event) {
        if (event.target.name === 'username') {
            this.setState({username: event.target.value});
        }
        if (event.target.name === 'password') {
            this.setState({password: event.target.value});
        }
    },

    render: function () {
        return <div>
            <div>Email:</div>
            <form>
                <input value={this.state.username}
                       onChange={this.handleChange}
                       type="email"
                       name="username"
                       placeholder="email"/>
                <div>Password:</div>
                <input type="password" placeholder="password"
                       onChange={this.handleChange}
                       type="password"
                       name="password" />
                <div className="login-buttons">
                    <Link className="btn btn-danger" to="/register">Register</Link>
                    <button type="submit" className="btn btn-info" onClick={this.handleLogin}>Login</button>
                </div>
                { this.renderError() }
                <div>
                    <Link to="/forgot">Forgot Password</Link>
                </div>
            </form>
        </div>
    },

    renderError: function(){
        if (this.props.hasError) {
            return <div className="alert alert-danger" role="alert">
                <span className="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>
                <span className="sr-only">Error:</span> Failed to log in.
            </div>
        }
    }
});