var React = require('react');

module.exports = React.createClass({

    render: function () {
        return <span> <div className="panel panel-default">
            <div className="panel-heading">
                <h2 className="panel-title">Multiplayer Video</h2>
            </div>
            <div className="panel-body">
                Here is a video of Ash and Gregor racing against each-other with a reasonably high ping (~200ms). <b>Note</b> this was an early version of the Beltoy Park track.

                <br/><br/>
                <center><iframe width="840" height="473" src="https://www.youtube.com/embed/dUPCfBhwV8g" frameBorder="0" allowFullScreen="true"></iframe></center>

            </div>
        </div>
        </span>
    }
});