var React = require("react");
var Link = require('react-router').Link;
var _ = require('lodash');

var Config = require('../config');

module.exports = React.createClass({

    getInitialState: function () {
        return {driversListTable:[], showMoreLabel: true};
    },

    componentWillReceiveProps: function(nextProps){
        if (nextProps.noMoreButton){
            this.showAll(nextProps);
        }else{
            this.showLess(nextProps);
        }
    },

    showAll: function (props) {
        this.setState({driversListTable: props.driversList, showMoreLabel: false})
    },

    showLess: function (props) {
        this.setState({driversListTable: _.slice(props.driversList, 0, 5), showMoreLabel: true})
    },

    more: function () {
        this.showAll(this.props);
    },

    less: function () {
        this.showLess(this.props);
    },

    render: function () {
        return <span>
            <div className="row">
                <div className="col-lg-12">
                    <h2 className="page-header">{this.props.title}</h2>
                </div>
            </div>
            < div className="row stats-box">
                <table className="table table-striped">
                    <thead>
                    <tr>
                        {this.renderHeaders()}
                    </tr>
                    </thead>
                    <tbody>
                    { this.renderDriverRows() }
                    </tbody>
                </table>
                { this.renderMoreButton()}
            </div>
        </span>
    },

    renderDriverRows: function () {
        return this.state.driversListTable.map(function (driver) {
            return <tr key={driver.id} className={ driver.id == this.props.driverId ? 'highlight-row' : ''}>
                <td>{driver.pos}</td>
                <td><Link to={'/profile/' + driver.id}><img style={ {marginLeft: '-10', marginRight:'5'} }
                                                            src={Config.apiUrl + '/user/avatar/' + driver.id + "?size=xs"}/>{driver.firstName + ' ' + driver.lastName}
                </Link></td>
                <td>{ this.renderDataValue(driver) }</td>
            </tr>
        }.bind(this))
    },

    renderHeaders: function(){
        if (this.props.headers) {
            return this.props.headers.map(function(header){
               return <th key={header}>{header}</th>
            });
        }
    },

    renderMoreButton: function () {
        if (!this.props.noMoreButton) {
            if (this.state.showMoreLabel) {
                return <button onClick={ this.more } className="btn btn-default fullwidth"><i
                    className="fa fa-angle-double-down"/></button>
            } else {
                return <button onClick={ this.less } className="btn btn-default fullwidth"><i
                    className="fa fa-angle-double-up"/></button>
            }
        }
    },

    renderDataValue: function (driver) {
        if (this.props.dataValue) {
            return driver[this.props.dataValue]
        }
    }

});