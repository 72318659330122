var self = module.exports = {
    licences : ["Amateur", "Club", "National B", "National A", "International B", "International A", "Pro B", "Pro A", "Elite"],
    colours: ["DarkGreen", "DarkBlue", "Purple", "DarkYellow", "DarkViolet", "DarkRed", "Black", "Silver", "Gold"],

    getPointsAtLevel: function (i) {
        return 500 * (i * (i + 1)) / 2;
    },

    getPointsAtLevelAndLicence: function (level, licenceIndex) {
        var level50Points = self.getPointsAtLevel(50);
        return (licenceIndex * level50Points) + self.getPointsAtLevel(level -1);
    },

    getLevelFromPoints: function (points) {
        var pointsDiv500 = Math.floor(points / 500);

        return Math.floor((-1 + Math.sqrt(1 + 8 * pointsDiv500)) / 2) + 1;
    },

    getRankAndColourFromPoints: function (points) {

        var colourLevel50Points = self.getPointsAtLevel(50);
        var licenceIndex = Math.floor(points / colourLevel50Points);
        var level;

        if (licenceIndex > 8){
            level = 50;
        }else{
            var pointsInThisLicence = points % colourLevel50Points;
            level = self.getLevelFromPoints(pointsInThisLicence);
        }

        licenceIndex = Math.min(8, licenceIndex);
        var licence = self.licences[licenceIndex];

        return {licenceName: licence, level: level, licenceIndex: licenceIndex, colour: self.colours[licenceIndex]};
    },

    getNextRankInfo: function(level, licenceIndex){
        if (licenceIndex >= self.licences.length && level >= 50){
            return 0;
        }

        if (level < 50){
            level++;
        }else{
            licenceIndex++;
            level = 1;
        }

        var nextLevelPoints = self.getPointsAtLevelAndLicence(level, licenceIndex);
        var licence = self.licences[licenceIndex];

        return {licenceName: licence, level: level, licenceIndex: licenceIndex, colour: self.colours[licenceIndex], points: nextLevelPoints};
    }
};