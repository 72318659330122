var React = require('react');
var Reflux = require('reflux');
var Link = require('react-router').Link;

var Actions = require('../actions');
var NewsStore = require('../stores/news-store');
var LoginStore = require('../stores/login-store');

var Footer = require('../components/footer');
var NewsItem = require('../components/news-item');


module.exports = React.createClass({
    mixins: [ Reflux.listenTo(NewsStore, 'onReceiveNews' ), Reflux.listenTo(LoginStore, 'onReceivePlayerData' )],


    getInitialState : function(){
        return { newsItems: [], pageInfo: null, player: null };
    },

    componentDidMount : function(){
        Actions.getMyDetails();
        Actions.getNews();
    },

    onReceiveNews: function(event, newsData){
        this.setState( { newsItems: newsData.news, pageInfo: newsData.paging })
    },

    onReceivePlayerData: function(event, playerData){
        if (event == 'myDetails'){
            this.setState({ player: playerData });
        }else if (event == 'logout'){
            this.setState({ player: null });
        }
    },

    render: function () {
        return <div className="container full-height">

            <div className="row">
                <div className="col-lg-12">
                    <h1 className="page-header">News { this.renderCreateNewsButton() }

                    </h1>
                    <ol className="breadcrumb">
                        <li><a href="index.html">Home</a>
                        </li>
                        <li className="active">News</li>
                    </ol>
                </div>
            </div>

            { this.renderNewsItem() }

            <hr />

            <Footer/>
        </div>
    },

    renderNewsItem: function(){
        return this.state.newsItems.map(function(post) {
            return <NewsItem key={post.id} news={post}/>
        })
    },

    isAdminUser: function(){
        return (this.state.player && this.state.player.userType.toLowerCase() == 'admin');
    },

    renderCreateNewsButton: function(){
        if (this.isAdminUser() && this.state.newsItems){
            return <Link to="/news/create"><i className="fa fa-plus-circle small"/></Link>
        }
    }

});