var React = require('react');

module.exports = React.createClass({

    render: function () {
        return <span> <div className="panel panel-default">
            <div className="panel-heading">
                <h2 className="panel-title">Post-race achievements</h2>
            </div>
            <div className="panel-body">
               Here is a screenshot of the list of achievements that are displayed after a race.  (Note all achievements were displayed for testing purposes)

                <img className="img-responsive" src="img/orc_achievements.jpg"/>
            </div>
        </div>
        </span>
    }
});