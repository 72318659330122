var React = require("react");

module.exports = React.createClass({

    showEdit: function(){

    },

    hideEdit: function(){

    },

    getImageClass : function(){
        var result;

        if (this.props.banner){
            result = 'fb-image-lg'
        }else{
            result = 'fb-image-profile thumbnail'
        }

        if (this.props.canEdit){
            result += ' hand';
        }

        return result;
    },

    getOnClick : function(){
        if (this.props.canEdit){
            return this.props.click;
        }else{
            return "";
        }
    },

    render: function () {
        return <span className="relative" onMouseOver={this.showEdit} onMouseOut={this.hideEdit}>
            <img onClick={this.getOnClick()} align="left"
                 className={ this.getImageClass() }
                 src={this.props.image}
            />
            </span>
    }
});