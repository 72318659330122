var Reflux = require('reflux');

module.exports = Reflux.createActions([
    'userLogin',
    'userRegister',
    'getUserDetails',
    'logout',
    'getFacebookAlbums',
    'getFacebookAlbum',
    'setCurrentAlbum',
    'getNews',
    'getNewsItem',
    'updateNewsItem',
    'addNewsItem',
    'getAuthToken',
    'getProfileData',
    'getMyDetails',
    'updateUser',
    'getPbsForPlayer',
    'checkFollowing',
    'follow',
    'unfollow',
    'getFollowing',
    'getFollowers',
    'getRankLeaderBoard',
    'getPointsLeaderBoard',
    'getWorldRecords',
    'getNearPointDrivers'
]
);