var React = require("react");

module.exports = React.createClass({

    getInitialState: function () {
        return {editMode: false}
    },

    onSave: function(){
        this.setState({ editMode: false });
        this.props.onSave();
    },
    onEdit: function(){
        this.setState({ editMode: true });
        this.props.onEdit();
    },
    onCancel: function(){
        this.setState({ editMode: false });
        this.props.onCancel();
    },

    render: function () {
        if (this.props.canEdit) {
            if (this.state.editMode) {
                return <div className="profile-edit-buttons">
                    <button onClick={this.onSave} className="btn btn-info">Save</button>
                    <button onClick={this.onCancel} className="btn btn-info">Cancel</button>
                </div>
            } else {
                return <div className="profile-edit-buttons"><button onClick={this.onEdit} className="btn btn-info">Edit</button></div>
            }
        }else{
            return <span></span>
        }
    }
});