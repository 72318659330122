var React = require('react');
var Time = require('../utils/time');

module.exports = React.createClass({
    render: function () {

        return <span>
            { this.getLaptimeString(this.props.time) }
        </span>
    },

    getLaptimeString: function (time) {
        if (time > 899) {
            return "--:--:--";
        } else {
            return Time.toMinSecs(time);
        }
    }
});