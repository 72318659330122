var React = require("react");

var Location = require('./profile-bestlaps-location');

module.exports = React.createClass({

    render: function () {
            return <span>
                {this.renderLocations()}
            </span> ;
    },

    renderLocations: function(){
        return this.props.laps.map(function(location){
            return <Location key={location.name} laps={location} showDriver={this.props.showDriver} worldRecords={this.props.worldRecords} driverId={this.props.driverId}/>
        }.bind(this));
    }
});