var React = require('react');
var Footer = require('../components/footer');

module.exports = React.createClass({

    render: function () {
        return <div className="container full-height">


            
            <div className="row">
                <div className="col-lg-12">
                    <h1 className="page-header">Downloads
                        <small> Alpha</small>
                    </h1>
                    <ol className="breadcrumb">
                        <li><a href="index.html">Home</a>
                        </li>
                        <li className="active">Downloads</li>
                    </ol>
                </div>
            </div>

            <div className="row">
                <div className="col-lg-12">
                    <img className="img-responsive" src="img/header1.jpg" alt="" />
                </div>
            </div>

            <div className="row">
                <div className="col-lg-12">
                    <br/>
                    Here is the download for ORC's alpha build, please report any problems to the forums (Your nickname / password as used when signing up for ORC)
                        <br />
                        <center><a className="btn btn-lg btn-danger text-center" href="http://www.siroccoracing.com/indev/OrcSetup.exe">Download ORC Alpha </a></center>
                        <br />
                        Be aware that ORC is still in early development and will change and improve quickly with your help!
                        <br/>
                        We hope you enjoy it!

                </div>
            </div>

            <hr />

            <Footer/>
        </div>
    }

});